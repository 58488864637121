import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

// 読み取り専用テキストボックス
function InputReadonlyBox(props) {
  const isError = props.errMessage !== undefined && props.errMessage !== '';
  return (
    <FormControl name={props.name} error={isError} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <TextField
        value={props.value || '未入力'}
        variant='outlined'
        size='small'
        disabled
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'black',
            background: '#BCC5CE'
          }
        }}
      />
      <div style={{ display: props.isUseHelperText ? 'block' : 'none' }}>
        <FormHelperText id='component-error-text'>
          {props.errMessage}
        </FormHelperText>
      </div>
    </FormControl>
  )
}

export default InputReadonlyBox