import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { convertToHENumbers } from './converter.js';

// 数字のみの入力ボックス
function InputNumberBox(props) {
  const isError = props.errMessage !== undefined && props.errMessage !== '';

  return (
    <FormControl name={props.name} error={isError} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <FormLabel sx={{ color: '#1a1a1a' }}>
        <Stack direction='row'>
          {props.label}
          <div style={{ display: props.isUseNecessity ? 'block' : 'none' }}>
            <div style={{ display: props.isRequired ? 'block' : 'none' }}>
              <div className='required'>必須</div>
            </div>
            <div style={{ display: !props.isRequired ? 'block' : 'none' }}>
              <div className='optional'>任意</div>
            </div>
          </div>
        </Stack>
      </FormLabel>
      <TextField
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        onBlur={e => props.onChange(convertToHENumbers(e.target.value))}
        error={isError}
        variant='outlined'
        size='small'
        type='Text'
        inputProps={{ inputMode: 'numeric' }}
      />
      <div style={{ display: props.isUseHelperText ? 'block' : 'none' }}>
        <FormHelperText id='component-error-text'>
          {props.errMessage}
        </FormHelperText>
      </div>
    </FormControl>
  )
}

export default InputNumberBox