import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

// 入力内容確認コンポーネント
function InputConfirmBox(props) {
  return (
    <FormControl variant='standard' style={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={6} style={{ overflowWrap: 'break-word', width: '100%', textAlign: 'start', padding: 10 }}>
          <FormLabel sx={{ color: '#1a1a1a', fontSize: 14 }}>
            {props.label}
          </FormLabel>
        </Grid>
        <Grid item xs={6} style={{ overflowWrap: 'break-word', width: '100%', textAlign: 'start', padding: 10 }}>
          <FormLabel sx={{ color: '#1a1a1a', fontSize: 14 }}>
            {props.value}
          </FormLabel>
        </Grid>
      </Grid>
    </FormControl>
  )
}

export default InputConfirmBox