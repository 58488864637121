import React, { useContext } from 'react';
import { Context } from './Content';
import { Outlet } from 'react-router';
import Logo from './Logo';
import Stepbar from './Stepbar';

function CommonFormat() {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;
  const { isCorrectTransition, setIsCorrectTransition } = isCorrectTransitionContext;

  if (isCorrectTransition) {
    return (
      <React.Fragment>
        <Stepbar />
        <Outlet />
        <Logo />
      </React.Fragment>
    )
  }
  else {
    window.location.assign('/error');
  }
}

export default CommonFormat