import Grid from '@mui/material/Grid';

function AuthorizationCompletedForm() {
	return (
		<Grid container alignItems='center' justifyContent='center' style={{  marginBottom: 50 }}>
			<Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
				ご登録が完了いたしました。<br />
				ご登録いただいたメールアドレスに、登録完了のお知らせをお送りしております。<br />
				補償金のお受け取りまで大切に保存してください。<br />
				<br />
				画面を閉じて終了してください。
			</Grid>
		</Grid>
	)
}

export default AuthorizationCompletedForm