import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const getuseWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [useWindowSize, setuseWindowSize] = useState(
    getuseWindowSize()
  );
  useEffect(() => {
    const onResize = () => {
      setuseWindowSize(getuseWindowSize());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return useWindowSize;
};

export default useWindowSize