import React, { useState, useContext } from 'react';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton';
import InputConfirmBox from '../components/InputConfirmBox';

// カテゴリ枠スタイル
const style = {
  border: 'solid 2px',
  borderRadius: '8px',
  borderColor: '#1a1a1a',
  textAlign: 'center',
  width: '100%',
  maxWidth: 600,
  marginLeft: 0,
};

// 入力内容確認画面
function CorporateRegisterForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // 連絡先住所が「法人登記上の所在地」の場合はCorporateBasicInfoFormの記載住所を参照する
  const contactAddressInfo = inputData['CorporateContactInfoForm'].contactAddressType === '0' ? inputData['CorporateContactInfoForm'] : inputData['CorporateBasicInfoForm'];

  // 口座種別変換
  const IndexToAccountType = (index) => {
    switch (index) {
      case '1':
        return '普通';
      case '2':
        return '当座';
      case '4':
        return '貯蓄';
    }
  }

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    // 登録ボタンの非活性化
    setLoading(true);

    const authCodes = [];
    authCodes.push({
      copyright_holder_id: inputData['AuthForm'].copyrightHolderId,
      code: inputData['AuthForm'].code
    });
    inputData['CorporateAdditionalCodeForm'].forEach(item => {
      authCodes.push({
        copyright_holder_id: item.copyrightHolderId,
        code: item.code
      });
    });
    const json = {
      'input_type': parseInt(inputData['TypeForm'].inputType),
      'authentication_codes': authCodes,
      'account': {
        'branch': inputData['CorporateAccountInfoForm'].branchId,
        'account_type': parseInt(inputData['CorporateAccountInfoForm'].accountType),
        'account_number': inputData['CorporateAccountInfoForm'].accountNumber,
        'account_name': inputData['CorporateAccountInfoForm'].accountName
      },
      'corporate_info': {
        'corporate_number': inputData['CorporateBasicInfoForm'].corporateNumber,
        'name': inputData['CorporateBasicInfoForm'].name,
        'name_furigana': inputData['CorporateBasicInfoForm'].nameFurigana,
        'registration_address': {
          'postal_code': inputData['CorporateBasicInfoForm'].postalCode,
          'region': inputData['CorporateBasicInfoForm'].region,
          'city': inputData['CorporateBasicInfoForm'].city,
          'address1': inputData['CorporateBasicInfoForm'].address1,
          'address2': inputData['CorporateBasicInfoForm'].address2,
        },
        'representative_name': inputData['CorporateBasicInfoForm'].representativeName,
        'representative_name_furigana': inputData['CorporateBasicInfoForm'].representativeNameFurigana,
        'contact_department': inputData['CorporateContactInfoForm'].contactDepartment,
        'contact_name': inputData['CorporateContactInfoForm'].contactName,
        'contact_name_furigana': inputData['CorporateContactInfoForm'].contactNameFurigana,
        'contact_phone_number': inputData['CorporateContactInfoForm'].contactPhoneNumber,
        'contact_email': inputData['CorporateContactInfoForm'].contactEmail,
        'contact_address': {
          'postal_code': contactAddressInfo.postalCode,
          'region': contactAddressInfo.region,
          'city': contactAddressInfo.city,
          'address1': contactAddressInfo.address1,
          'address2': contactAddressInfo.address2,
        },
        'invoice_number': inputData['CorporateBasicInfoForm'].invoiceNumber,
      }
    };

    axios
      .post(
        process.env.REACT_APP_API_DOMAIN + '/v1/register/',
        json
      )
      .then(res => {
        props.handleTransition('/self/corporate/completed');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        <p>入力内容をご確認ください。</p>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <span style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>権利者（法人）の基本情報</span>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人番号'
                value={inputData['CorporateBasicInfoForm'].corporateNumber}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人名'
                value={inputData['CorporateBasicInfoForm'].name}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人名(カタカナ)'
                value={inputData['CorporateBasicInfoForm'].nameFurigana}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人登記上の郵便番号'
                value={inputData['CorporateBasicInfoForm'].postalCode}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人登記上の都道府県'
                value={inputData['CorporateBasicInfoForm'].region}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人登記上の市区町村'
                value={inputData['CorporateBasicInfoForm'].city}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人登記上の町域・番地'
                value={inputData['CorporateBasicInfoForm'].address1}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='法人登記上の建物名など'
                value={inputData['CorporateBasicInfoForm'].address2}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='代表者氏名'
                value={inputData['CorporateBasicInfoForm'].representativeName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='代表者氏名(カタカナ)'
                value={inputData['CorporateBasicInfoForm'].representativeNameFurigana}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='インボイス発行事業者登録番号'
                value={inputData['CorporateBasicInfoForm'].invoiceNumber}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <span style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>権利者（法人）の連絡先情報</span>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先担当部署'
                value={inputData['CorporateContactInfoForm'].contactDepartment}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先担当者'
                value={inputData['CorporateContactInfoForm'].contactName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先担当者(カタカナ)'
                value={inputData['CorporateContactInfoForm'].contactNameFurigana}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先メールアドレス'
                value={inputData['CorporateContactInfoForm'].contactEmail}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先電話番号'
                value={inputData['CorporateContactInfoForm'].contactPhoneNumber}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='郵便番号'
                value={contactAddressInfo.postalCode}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='都道府県'
                value={contactAddressInfo.region}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='市区町村'
                value={contactAddressInfo.city}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='町域・番地'
                value={contactAddressInfo.address1}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='建物名など'
                value={contactAddressInfo.address2}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <span style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>権利者（法人）の口座情報</span>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='銀行名'
                value={inputData['CorporateAccountInfoForm'].bankName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='支店番号'
                value={inputData['CorporateAccountInfoForm'].branchName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>

              <InputConfirmBox
                label='預金種類'
                value={IndexToAccountType(inputData['CorporateAccountInfoForm'].accountType)}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='口座番号'
                value={inputData['CorporateAccountInfoForm'].accountNumber}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='口座名義'
                value={inputData['CorporateAccountInfoForm'].accountName}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <span style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>入力した権利者ID</span>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label={'1番目'}
                value={inputData['AuthForm'].copyrightHolderId}
              />
            </Grid>
            {inputData['CorporateAdditionalCodeForm'].map((item, index) => (
              <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                <InputConfirmBox
                  label={(index + 2).toString() + '番目'}
                  value={item.copyrightHolderId}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center', marginTop: 50 }}>
        <p><b>【必ずご確認ください】</b></p>
      </Grid>

      <Grid item>
        <p>・ご入力いただいた情報のセキュリティを確保するため、登録完了後はご自身でも入力内容を確認いただくことはできません。</p>
        <p>特に「法人名」や「連絡先メールアドレス」、「口座情報」などにお間違いがないかご確認をお願いいたします。</p>
        <p>登録内容の一部は、登録後に自動で送信されます「登録完了のお知らせ」メールに記載しております。</p>
        <br />
        <p>・以下の「登録」ボタンにより、ご入力いただいた情報の登録が完了いたします。</p>
        <p>登録の完了により、入力いただいた認証コードは失効しますので、前画面の著作物情報や補償金額のDL保存など、今一度ご確認をお願いいたします。</p>
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              color='primary'
              inputProps={{ 'data-testid': 'input-checkbox' }}
            />
          }
          onChange={(e) => setIsChecked(e.target.checked)}
          label='確認しました'
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='登録'
          variant='contained'
          disabled={!isChecked || loading}
          onClick={handleTransitionNext}
          dataTestId='input-nextbutton'
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/self/corporate/totaldetails')}
        />
      </Grid>
    </Grid>
  )
}

export default CorporateRegisterForm