import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import Fade from '@mui/material/Fade';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='aaa - aaa - aaa - aaa'
      definitions={{
        'a': /[0-9a-zA-Z]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

// 認証コード入力テキストボックス
function InputCodeBox(props) {
  const isError = props.errMessage !== undefined && props.errMessage !== '';
  const dataTestId = props.dataTestId;
  return (
    <FormControl error={isError} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <Stack direction='row'>
        <FormLabel sx={{ color: '#1a1a1a' }}>
          {props.label}
        </FormLabel>
      </Stack>
      <TextField
        id={props.id}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value.replace(/ - /g, ''))}
        error={isError}
        variant='outlined'
        size='small'
        InputProps={{ inputComponent: TextMaskCustom }}
        inputProps={{ 'data-testid': dataTestId }}
      />
      <p style={{ fontSize: 12, textAlign: 'left' }}>※認証コードを入力すると3桁ごとに自動で-(ハイフン)が表示されます。</p>
      <Fade in={props.isUseHelperText} style={{ display: props.isUseHelperText ? 'block' : 'none' }}>
        <FormHelperText id='component-error-text'>
          {props.errMessage}
        </FormHelperText>
      </Fade>
    </FormControl>
  )
}

export default InputCodeBox