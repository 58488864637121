import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton';
import DetailComponent from '../components/DetailComponent';

function AgentRegisterForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item>
				<p>追加でご登録いただいた権利者ID等も含めた、著作物情報及び補償金額の確認をお願いいたします。</p>
				<p>なお、登録完了後の再確認はできませんので、本画面の「一括DL」などにより保存いただきますようお願いいたします。</p>
				<p>※「DL」ボタンを押すと、それぞれの明細をPDFファイルとしてダウンロードすることができます。</p>
				<p>※「明細」ボタンを押すと、その権利者IDに紐づけられたすべての著作物情報が表示されます。</p>
				<p>※表示の金額から、分配業務受託団体等が規程に定める手数料や諸税を控除したうえで、ご指定の口座へ振り込みます。</p>
			</Grid>

			<Grid item style={{ width: '100%', maxWidth: 600, marginTop: 20 }}>
				<Grid item style={{ width: '100%', maxWidth: 600, marginTop: 20 }}>
					<DetailComponent
						data={inputData['Details']}
						isAdditional={true}
					/>
				</Grid>
			</Grid>

			<Grid item>
				<CustomButton
					label='次へ'
					variant='contained'
					onClick={() => props.handleTransition('/others/agent/register')}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/others/agent/additionalcode')}
				/>
			</Grid>
		</Grid >
	)
}

export default AgentRegisterForm