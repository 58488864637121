import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';

// ステップバー
function Stepbar() {

  const url = useLocation().pathname;
  const jsonData = require('./stepCount.json');
  const { stepNumber, stepName } = jsonData[url];

  return (
    <Grid container spacing={1} alignItems='center' justifyContent='center' direction='column' style={{ width: '100%' }}>
      <Grid item xs={12} style={{ width: '100%', marginBottom: 20 }}>
        <Stepper activeStep={stepNumber}>
          {
            (function () {
              const list = [];
              for (let i = 0; i < 7; i++) {
                list.push(<Step><StepLabel></StepLabel></Step>);
              }
              return list;
            }())
          }
        </Stepper>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 50 }}>
        {stepName}
      </Grid>
    </Grid >
  )
}

export default Stepbar