import React from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import InputTextBox from './InputTextBox';
import InputNumberBox from './InputNumberBox';
import InputEmailBox from './InputEmailBox';
import axios from 'axios';
import '../contents.css';

function CorporateContactInfo(props) {

  // 郵便番号から住所情報を自動入力する
  const handleChangePostalCode = (value) => {
    props.setPostalCode(value);
    axios
      .get('https://api.zipaddress.net/?zipcode=' + value)
      .then(res => {
        const success = res.data.code === 200;
        props.setRegion(prevState => success ? res.data.data.pref : prevState);
        props.setCity(prevState => success ? res.data.data.city : prevState);
        props.setAddress1(prevState => success ? res.data.data.town : prevState);
        props.setAddress2(prevState => success ? '' : prevState);
        props.setErrMessage({ ...props.errMessage, address1: success ? '番地以降をご入力ください' : '' });
      });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='contactDepartment'
            name='contactDepartment'
            label='連絡先担当部署'
            placeholder='総務部'
            value={props.contactDepartment}
            onChange={props.setContactDepartment}
            errMessage={props.errMessage.contactDepartment}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='contactName'
            name='contactName'
            label='連絡先担当者'
            placeholder='連絡　太郎'
            value={props.contactName}
            onChange={props.setContactName}
            errMessage={props.errMessage.contactName}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='contactNameFurigana'
            name='contactNameFurigana'
            label='連絡先担当者（カタカナ）'
            placeholder='レンラク　タロウ'
            value={props.contactNameFurigana}
            onChange={props.setContactNameFurigana}
            errMessage={props.errMessage.contactNameFurigana}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputEmailBox
            id='contactEmail'
            name='contactEmail'
            label='連絡先メールアドレス'
            placeholder='example@sartras.or.jp'
            type='email'
            value={props.contactEmail}
            onChange={props.setContactEmail}
            errMessage={props.errMessage.contactEmail}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputNumberBox
            id='contactPhoneNumber'
            name='contactPhoneNumber'
            label='連絡先電話番号'
            placeholder='0312345678'
            value={props.contactPhoneNumber}
            onChange={props.setContactPhoneNumber}
            errMessage={props.errMessage.contactPhoneNumber}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={false}
          />
        </Grid>

        <Grid item alignItems='flex-start' style={{ width: '100%', textAlign: 'left' }}>
          <p>担当者の連絡先住所をご入力ください。</p>
        </Grid>

        <Grid item alignItems='flex-start' style={{ width: '100%', textAlign: 'left' }}>
          <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
            <RadioGroup
              defaultValue='0'
              name='contactAddressType'
              value={props.contactAddressType}
              onChange={(e) => props.setContactAddressType(e.target.value)}
            >
              <FormControlLabel value='0' control={<Radio color='primary' />} label='連絡先の住所を別途入力する' />
              <FormControlLabel value='1' control={<Radio color='primary' />} label='法人登記上の所在地と同じ' />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column' style={{ marginTop: 20, display: props.contactAddressType === '0' ? 'block' : 'none' }}>
        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputNumberBox
            id='postalCode'
            name='postalCode'
            label='郵便番号'
            placeholder='1000014'
            value={props.postalCode}
            onChange={handleChangePostalCode}
            errMessage={props.errMessage.postalCode}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='region'
            name='region'
            label='都道府県'
            placeholder='東京都'
            value={props.region}
            onChange={props.setRegion}
            errMessage={props.errMessage.region}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='city'
            name='city'
            label='市区町村'
            placeholder='千代田区'
            value={props.city}
            onChange={props.setCity}
            errMessage={props.errMessage.city}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='address1'
            name='address1'
            label='町域・番地'
            placeholder='永田町２丁目４−３'
            value={props.address1}
            onChange={props.setAddress1}
            errMessage={props.errMessage.address1}
            isUseHelperText={true}
            isUseNecessity={true}
            isRequired={true}
          />
        </Grid>

        <Grid item style={{ width: '100%', textAlign: 'center' }}>
          <InputTextBox
            id='address2'
            name='address2'
            label='建物名など'
            placeholder='永田町ビル 6F'
            value={props.address2}
            onChange={props.setAddress2}
            isUseHelperText={false}
            isUseNecessity={true}
            isRequired={false}
          />
        </Grid>
      </Grid >
    </React.Fragment >
  )
}

export default CorporateContactInfo