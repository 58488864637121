import React, { useState, useContext } from 'react';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton';
import InputConfirmBox from '../components/InputConfirmBox';

// カテゴリ枠スタイル
const style = {
  border: 'solid 2px',
  borderRadius: '8px',
  borderColor: '#1a1a1a',
  textAlign: 'center',
  width: '100%',
  maxWidth: 600,
  marginLeft: 0,
};

function IndividualRegisterForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const accountName = inputData['IndividualAccountInfoForm'].accountSei + (inputData['IndividualAccountInfoForm'].accountMei.match(/\S/g) ? ' ' + inputData['IndividualAccountInfoForm'].accountMei : '');

  // 口座種別変換
  const IndexToAccountType = (index) => {
    switch (index) {
      case '1':
        return '普通';
      case '2':
        return '当座';
      case '4':
        return '貯蓄';
    }
  }

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    // 登録ボタンの非活性化
    setLoading(true);
    
    const authCodes = [];
    authCodes.push({
      copyright_holder_id: inputData['AuthForm'].copyrightHolderId,
      code: inputData['AuthForm'].code
    });
    inputData['IndividualAdditionalCodeForm'].forEach(item => {
      authCodes.push({
        copyright_holder_id: item.copyrightHolderId,
        code: item.code
      });
    });
    const json = {
      'input_type': parseInt(inputData['TypeForm'].inputType),
      'authentication_codes': authCodes,
      'account': {
        'branch': inputData['IndividualAccountInfoForm'].branchId,
        'account_type': parseInt(inputData['IndividualAccountInfoForm'].accountType),
        'account_number': inputData['IndividualAccountInfoForm'].accountNumber,
        'account_name': accountName,
      },
      'individual_info': {
        'first_name': inputData['IndividualBasicInfoForm'].firstName,
        'first_name_furigana': inputData['IndividualBasicInfoForm'].firstNameFurigana,
        'last_name': inputData['IndividualBasicInfoForm'].lastName,
        'last_name_furigana': inputData['IndividualBasicInfoForm'].lastNameFurigana,
        'phone_number': inputData['IndividualBasicInfoForm'].phoneNumber,
        'email': inputData['IndividualBasicInfoForm'].email,
        'invoice_number': inputData['IndividualBasicInfoForm'].invoiceNumber,
        'address': {
          'postal_code': inputData['IndividualAddressInfoForm'].postalCode,
          'region': inputData['IndividualAddressInfoForm'].region,
          'city': inputData['IndividualAddressInfoForm'].city,
          'address1': inputData['IndividualAddressInfoForm'].address1,
          'address2': inputData['IndividualAddressInfoForm'].address2,
        }
      }
    };

    axios
      .post(
        process.env.REACT_APP_API_DOMAIN + '/v1/register/',
        json
      )
      .then(res => {
        props.handleTransition('/self/individual/completed');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        <p>入力内容をご確認ください。</p>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <p style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>権利者（個人）の基本情報</p>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='姓'
                value={inputData['IndividualBasicInfoForm'].lastName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='名'
                value={inputData['IndividualBasicInfoForm'].firstName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='姓(フリガナ)'
                value={inputData['IndividualBasicInfoForm'].lastNameFurigana}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='名(フリガナ)'
                value={inputData['IndividualBasicInfoForm'].firstNameFurigana}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先メールアドレス'
                value={inputData['IndividualBasicInfoForm'].email}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='連絡先電話番号'
                value={inputData['IndividualBasicInfoForm'].phoneNumber}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='インボイス発行事業者登録番号'
                value={inputData['IndividualBasicInfoForm'].invoiceNumber}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <p style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>権利者（個人）の住所情報</p>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='郵便番号'
                value={inputData['IndividualAddressInfoForm'].postalCode}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='都道府県'
                value={inputData['IndividualAddressInfoForm'].region}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='市区町村'
                value={inputData['IndividualAddressInfoForm'].city}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='町域・番地'
                value={inputData['IndividualAddressInfoForm'].address1}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='建物名など'
                value={inputData['IndividualAddressInfoForm'].address2}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <p style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>権利者（個人）の口座情報</p>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='銀行名'
                value={inputData['IndividualAccountInfoForm'].bankName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='支店番号'
                value={inputData['IndividualAccountInfoForm'].branchName}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='預金種類'
                value={IndexToAccountType(inputData['IndividualAccountInfoForm'].accountType)}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='口座番号'
                value={inputData['IndividualAccountInfoForm'].accountNumber}
              />
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label='口座名義'
                value={accountName}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <p style={{ verticalAlign: 'left', color: '#1a1a1a', fontWeight: 'bold' }}>入力した権利者ID</p>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
              <InputConfirmBox
                label={'1番目'}
                value={inputData['AuthForm'].copyrightHolderId}
              />
            </Grid>
            {inputData['IndividualAdditionalCodeForm'].map((item, index) => (
              <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                <InputConfirmBox
                  label={(index + 2).toString() + '番目'}
                  value={item.copyrightHolderId}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center', marginTop: 50 }}>
        <p><b>【必ずご確認ください】</b></p>
      </Grid>

      <Grid item>
        <p>・ご入力いただいた情報のセキュリティを確保するため、登録完了後はご自身でも入力内容を確認いただくことはできません。</p>
        <p>特に「姓」「名」や「連絡先メールアドレス」、「口座情報」などにお間違いがないかご確認をお願いいたします。</p>
        <p>登録内容の一部は、登録後に自動で送信されます「登録完了のお知らせ」メールに記載しております。</p>
        <br />
        <p>・以下の「登録」ボタンにより、ご入力いただいた情報の登録が完了いたします。</p>
        <p>登録の完了により、入力いただいた認証コードは失効しますので、前画面の著作物情報や補償金額のDL保存など、今一度ご確認をお願いいたします。</p>
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              color='primary'
              inputProps={{ 'data-testid': 'input-checkbox' }}
            />
          }
          onChange={(e) => setIsChecked(e.target.checked)}
          label='確認しました'
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='登録'
          variant='contained'
          disabled={!isChecked || loading}
          onClick={handleTransitionNext}
          dataTestId='input-nextbutton'
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/self/individual/totaldetails')}
        />
      </Grid>
    </Grid >
  )
}

export default IndividualRegisterForm