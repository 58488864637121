import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { makeStyles } from "@material-ui/core/styles";
import { Context } from '../Content';
import CustomButton from './components/CustomButton'
import { GRAY_FRAME } from './components/colors.js'

import inputType0 from './images/inputType0.png';
import inputType1 from './images/inputType1.png';
import inputType2 from './images/inputType2.png';

// ラジオ選択画像を自動で縦に並べるスタイル
const AutoRadioPosition = makeStyles((theme) => ({
  auto: {
    margin: theme.spacing(1)
  }
}))

// ラジオ選択画像のスタイル
const radioFrame = {
  border: '1px solid' + GRAY_FRAME,
  width: '100%',
  height: 'auto'
}

// 選択に応じた説明文ボックスのスタイル
const boxStyle = {
  border: 'solid 1px',
  borderRadius: '4px',
  borderColor: GRAY_FRAME,
  textAlign: 'left',
  width: 'auto',
  minWidth: '400px',
  maxWidth: '600px',
  padding: 2,
}

function TypeForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [inputType, setInputType] = useState(inputData['TypeForm']?.inputType);

  // ラジオボタン変更時の処理
  const onChangeRadio = (value) => {
    setInputType(value);
    setInputData({ ...inputData, 'TypeForm': { inputType: value } });
  };

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    switch (inputType) {
      case '0':
        props.handleTransition('/self/individual/basicinfo');
        break;
      case '1':
        props.handleTransition('/self/corporate/basicinfo');
        break;
      case '2':
        props.handleTransition('/others/authorization/basicinfo');
        break;
      case '3':
        props.handleTransition('/others/agent/corporatebasicinfo');
        break;
    }
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        <p>
          補償金を最終的に受け取る権利者と振込先口座の所有者の関係によって、諸税の取扱いやご入力いただく情報などが異なりますので、下記から該当するものをご選択ください。<br />
          ※下記のいずれにも該当しない個別のご事情がある場合などは、<a href='mailto:bunpai@sartras.or.jp'>bunpai@sartras.or.jp</a>までお問い合わせください。
        </p>
      </Grid>

      <RadioGroup
        name='radio'
        value={inputType}
        onChange={(e) => onChangeRadio(e.target.value)}
      >
        <Grid item container spacing={3} style={{ marginTop: 10, width: '100%' }} alignItems='flex-start' justifyContent='center'>
          <Grid item>
            <FormControlLabel
              value='0'
              control={<Radio />}
              label={<img src={inputType0} priority={true} width={320} />}
              labelPlacement='bottom'
              className={AutoRadioPosition.auto}
              style={radioFrame}
            />
          </Grid>

          <Grid item>
            <Stack>
              <FormControlLabel
                value='1'
                control={<Radio />}
                label={<img src={inputType1} priority={true} width={320} />}
                labelPlacement='bottom'
                className={AutoRadioPosition.auto}
                style={radioFrame}
              />
              <p style={{ fontSize: '12px', paddingLeft: '20px' }}>※法人のご担当者様が法人として入力される場合は、<br />こちらをご選択ください。</p>
            </Stack>
          </Grid>

          <Grid item>
            <FormControlLabel
              value='2'
              control={<Radio />}
              label={<img src={inputType2} priority={true} width={320} />}
              labelPlacement='bottom'
              className={AutoRadioPosition.auto}
              style={radioFrame}
            />
          </Grid>
        </Grid>
      </RadioGroup>

      <Grid item style={{ display: inputType === '2' ? 'block' : 'none' }}>
        <Box sx={{ ...boxStyle }}>
          <div style={{ color: 'red' }}>
            <p>【必ずお読みください】</p>
            <p>分配業務受託団体等は、受領を委任された法人の口座にお振込みを行い、同法人を宛先とする支払い明細書を発行いたします。</p>
            <p>源泉徴収等の処理は同法人において行っていただくこととなります。</p>
          </div>
        </Box>
      </Grid>

      <Grid item style={{ display: inputType === '3' ? 'block' : 'none' }}>
        <Box sx={{ ...boxStyle }}>
          <div style={{ color: 'red' }}>
            <p>【必ずお読みください】</p>
            <p>分配業務受託団体等は、受領を代理される法人の口座にお振込みを行い、同法人が代理されている権利者を宛先とする支払い明細書を発行いたします。（権利者の氏名、住所等の登録が必須となります。）</p>
            <p>源泉徴収等の処理は分配業務受託団体等において行います。</p>
          </div>
        </Box>
      </Grid>

      <Grid item>
        <CustomButton
          label='次へ'
          variant='contained'
          onClick={handleTransitionNext}
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/details')}
        />
      </Grid>
    </Grid >
  )
}

export default TypeForm