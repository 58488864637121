import { ACCOUNT_NAME_REGEX, NUMBER_ONLY_REGEX } from './validateInputs.js'

const moji = require("moji");

// 口座名義のアンマッチ正規表現
export const UNMATCHED_ACCOUNT_NAME_REGEX = new RegExp(`[^${ACCOUNT_NAME_REGEX}]`, 'g');

// 全角英数を半角英数に変換
export const convertToHENumbers = s => {
  const numbers = s.replace(new RegExp(`[^${NUMBER_ONLY_REGEX}]`, 'g'), '');
  return moji(numbers).convert('ZE', 'HE').toString();
}

// 口座名義に使用可能な文字に変換
export const convertToHalfWidthAccountName = s => {
  // ハイフンの置換
  let result = s.replace(/[－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-');
  // ピリオドの置換
  result = result.replace(/[。．]/g, '.');
  // 全角英数→半角英数、全角スペース→半角スペース、ひらがな→カタカナ、全角カタカナ→半角カタカナの変換
  result = moji(result).convert('ZE', 'HE').convert('ZS', 'HS').convert('HG', 'KK').convert('ZK', 'HK').toString();
  return result.replace(UNMATCHED_ACCOUNT_NAME_REGEX, '');
}