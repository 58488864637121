import './App.css';
import React from 'react';
import Container from '@mui/material/Container';
import Header from './Header';
import Content from './Content';
import useWindowSize from './contents/components/useWindowSize';

// コンテンツ表示部分スタイル
const style = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 2,
  paddingRight: 2,
};

function App() {
  const { width, height } = useWindowSize();
  let contentsWidth = width * 0.9;
  if (contentsWidth > 800) {
    contentsWidth = 800 * 0.9;
  }
  return (
    <React.Fragment>
      <Header />
      <Container maxWidth='md' sx={{ ...style }}>
        <Content />
      </Container>
    </React.Fragment>
  );
}

export default App;