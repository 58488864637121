import React, { useState } from 'react';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import InputTextBox from './InputTextBox';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

const columns = [
  {
    field: 'name',
    headerName: '法人名',
    width: 200,
    disableColumnMenu: true,
    hideSortIcons: false,
    sortable: false,
    disableReorder: false,
  },
  {
    field: 'address',
    headerName: '法人登記上の住所',
    width: 296,
    disableColumnMenu: true,
    hideSortIcons: false,
    sortable: false,
    disableReorder: false,
  },
];

// モーダルのスタイル
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  border: 'solid 1px',
  borderRadius: '4px',
  borderColor: '#1976D2',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

// 法人検索ダイアログ
function SearchCorporateModal(props) {
  const [searchName, setSearchName] = useState('');
  const [searchRegion, setSearchRegion] = useState('');
  const [resultList, setResultList] = useState([]);
  const [selectedCorporateInfo, setSelectedCorporateInfo] = useState(null);
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const regionList = require('./regionList.json');

  const handleClickSarch = () => {
    setSelectedCorporateInfo(null);
    setErrMessage('');
    if (!searchName.match(/\S/g)) {
      setErrMessage('法人名を入力してください');
    }
    else {
      setIsLoading(true);
      setResultList([]);
      axios
        .get(process.env.REACT_APP_API_DOMAIN + '/v1/corporate/?name=' + searchName + (searchRegion !== '' ? '&region=' + searchRegion : ''))
        .then(res => {
          const corporateList = [];
          res.data.forEach((element, index) => {
            corporateList.push({
              id: index,
              name: element.name,
              address: `〒${element.registration_address.postal_code} ${element.registration_address.region}${element.registration_address.city}${element.registration_address.address1}${element.registration_address.address2}`,
              data: element
            });
          });
          setIsLoading(false);
          setResultList(corporateList);
        })
        .catch(() => {
          setIsLoading(false);
          setResultList([]);
          setErrMessage('該当する情報は見つかりませんでした。');
        });
    }
  };

  const handleKeyPress = (e) => {
    if (!e.nativeEvent.isComposing && e.key === 'Enter') handleClickSarch();
  }

  return (
    <Box sx={{ ...style }}>
      <Stack direction='row' justifyContent='flex-end' style={{ marginTop: 0, marginRight: 0 }}>
        <IconButton
          onClick={props.handleClose}
          size='small'
        >
          <CloseIcon sx={{ color: '#D32F2F' }} />
        </IconButton>
      </Stack>
      <Grid container spacing={3} alignItems='center' justifyContent='center' >
        <Grid item xs={12}>
          <b>法人名で検索後、該当する法人を選択</b>
        </Grid>
        <Grid item xs={2}>
          法人名
        </Grid>
        <Grid item xs={10}>
          <InputTextBox
            value={searchName}
            onChange={setSearchName}
            onKeyUp={handleKeyPress}
            isUseHelperText={false}
            isUseNecessity={false}
            isRequired={false}
          />
        </Grid>
        <Grid item xs={2}>
          所在地
        </Grid>
        <Grid item xs={7}>
          <Autocomplete
            disablePortal
            options={regionList}
            renderInput={(params) => <TextField {...params} label='都道府県を選択（任意）' />}
            onChange={e => setSearchRegion(e.target.textContent)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='contained'
            onClick={handleClickSarch}
            disabled={isLoading}
            fullWidth
          >
            検索
          </Button>
        </Grid>

        <Grid item xs={14} style={{ fontSize: '12px' }}>
          ※当該検索サービスは国税庁法人番号システムのWeb-API機能を利用して取得した情報をもとに作成されています。サービスの内容は国税庁によって保証されたものではありません。
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              border: 'solid 1px',
              borderRadius: '8px',
              borderColor: '#5c5c5c',
              display: 'flex',
              flexWrap: 'wrap',
              bgcolor: 'background.paper',
              maxWidth: 800,
              height: 240
            }}
          >
            <DataGrid
              rows={resultList}
              columns={columns}
              onRowClick={e => setSelectedCorporateInfo(e.row.data)}
              hideFooter
              localeText={{
                noRowsLabel: errMessage
              }}
              sx={{
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell': {
                  height: 'auto',
                  whiteSpace: 'normal !important'
                },
                '& .css-1w53k9d-MuiDataGrid-overlay': {
                  color: 'red',
                },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '-250px' }}>
          <Fade in={isLoading}>
            <CircularProgress />
          </Fade>
        </Grid>

        <Grid item xs={12} style={{ fontSize: '10px', marginTop: '-20px' }}>
          ※当該検索サービスは国税庁法人番号システムのWeb-API機能を利用して取得した情報をもとに作成されています。サービスの内容は国税庁によって保証されたものではありません。
        </Grid>

        <Grid item xs={12}>
          <Button variant='contained' disabled={!selectedCorporateInfo} onClick={() => props.handleConfirm(selectedCorporateInfo)} >
            選択した法人の情報を、自動入力する
          </Button>
        </Grid>
      </Grid>
    </Box >
  );
};

export default SearchCorporateModal