import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import SearchBankModal from '../components/SearchBankModal'
import SearchBranchModal from '../components/SearchBranchModal.jsx'
import InputAccountNameBox from '../components/InputAccountNameBox';
import InputNumberBox from '../components/InputNumberBox';
import AccordionComponent from '../components/AccordionComponent';
import InputReadonlyBox from '../components/InputReadonlyBox';
import CorporatePersonalityAbbreviationTable from '../components/CorporatePersonalityAbbreviationTable';
import { BUTTON_COLOR } from '../components/colors.js'
import searchIcon from '../images/search_icon.png';
import '../contents.css';

// 注意書きボックスのスタイル
const style = {
  height: 'auto',
  border: 'solid 3px',
  borderRadius: '2px',
  borderColor: 'red',
  padding: 5
};

function CorporateAccountInfo(props) {
  const [isSearchBankModalOpen, setIsSearchBankModalOpen] = useState(false);
  const [isSearchBranchModalOpen, setIsSearchBranchModalOpen] = useState(false);

  // Modalのスタイル
  const modalStyles = {
    content: {
      top: '10%',
      left: '10%',
      right: '10%',
    },
  };

  // 銀行名決定イベント
  const onDecideBank = (selection) => {
    setIsSearchBankModalOpen(false);
    props.setBankName(selection.name);
    props.setBankCode(selection.code);
    props.setBranchName('');
    props.setBranchId('');
  }

  // 支店名決定イベント
  const onDecideBranch = (selection) => {
    setIsSearchBranchModalOpen(false);
    props.setBranchName(selection.name);
    props.setBranchId(selection.id)
  }

  // ダイアログ閉じるイベント
  const onClose = () => {
    setIsSearchBankModalOpen(false);
    setIsSearchBranchModalOpen(false);
  }

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='bankCode' error={props.errMessage.bankCode} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              銀行名
              <span className='required'>必須</span>
            </Stack>
          </FormLabel>
          <Button variant='outlined' color="inherit" sx={BUTTON_COLOR} style={{ marginBottom: 5 }} onClick={() => setIsSearchBankModalOpen(true)} >
            <p><img src={searchIcon} align='top' width='20' height='20' />銀行名を検索</p>
          </Button>
          <Modal
            hideBackdrop
            open={isSearchBankModalOpen}
            style={modalStyles}
          >
            <SearchBankModal
              onDecideBank={onDecideBank}
              onClose={onClose}
            />
          </Modal>
          <InputReadonlyBox
            value={props.bankName}
            errMessage={props.errMessage.bankCode}
            isUseHelperText={true}
          />
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='branchId' error={props.errMessage.branchId} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              支店名
              <span className='required'>必須</span>
            </Stack>
          </FormLabel>
          <Button
            variant='outlined' color="inherit" sx={BUTTON_COLOR} style={{ marginBottom: 5 }} onClick={() => setIsSearchBranchModalOpen(true)} >
            <p><img src={searchIcon} align='top' width='20' height='20' />支店名を検索</p>
          </Button>
          <Modal
            hideBackdrop
            open={isSearchBranchModalOpen}
            style={modalStyles}
          >
            <SearchBranchModal
              bankCode={props.bankCode}
              onDecideBranch={onDecideBranch}
              onClose={onClose}
            />
          </Modal>
          <InputReadonlyBox
            value={props.branchName}
            errMessage={props.errMessage.branchId}
            isUseHelperText={true}
          />
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <Grid container>
            <span>預金種類</span>
            <span className='required'>必須</span>
          </Grid>
          <RadioGroup
            defaultValue='0'
            name='accountType'
            value={props.accountType}
            onChange={(e) => props.setAccountType(e.target.value)}
          >
            <FormControlLabel value='1' control={<Radio color='primary' />} label='普通' />
            <FormControlLabel value='2' control={<Radio color='primary' />} label='当座' />
            <FormControlLabel value='4' control={<Radio color='primary' />} label='貯蓄' />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputNumberBox
          id='accountNumber'
          name='accountNumber'
          label='口座番号'
          placeholder='1234567'
          value={props.accountNumber}
          onChange={props.setAccountNumber}
          errMessage={props.errMessage.accountNumber}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='accountName' variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              <p>口座名義</p>
              <p className='required'>必須</p>
            </Stack>
            <div style={{ fontSize: '12px', textAlign: 'left' }}>
              <p>※ご入力いただいた口座名義は自動的に半角文字に変換されます。使用可能な文字は以下の通りです。</p>
              <p>カタカナ、英数字、スペース、　<ruby> - <rt>ﾊｲﾌﾝ</rt> </ruby>　 <ruby> () <rt>丸括弧</rt> </ruby> 　<ruby> 「」 <rt>鉤括弧</rt> </ruby> 　<ruby> . <rt>ﾋﾟﾘｵﾄﾞ</rt> </ruby>　<ruby> / <rt>ｽﾗｯｼｭ</rt> </ruby></p>
            </div>
          </FormLabel>
          <InputAccountNameBox
            id='accountName'
            label=''
            placeholder='ｼﾔ)ｼﾞｭｷﾞｮｳﾓｸﾃｷｺｳｼｭｳｿｳｼﾝﾎｼｮｳｷﾝﾄｳｶﾝﾘｷｮｳｶｲ'
            value={props.accountName}
            setValue={props.setAccountName}
            errMessage={props.errMessage.accountName}
            isUseHelperText={true}
          />
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <AccordionComponent label='参考：法人種別略称一覧' Details={CorporatePersonalityAbbreviationTable} />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
            <Grid item style={{ width: '100%', textAlign: 'center' }}>
              <font color='red'><b>＜必ずご確認ください＞</b></font>
            </Grid >

            <Grid item style={{ width: '100%', textAlign: 'left' }}>
              <p>口座情報に誤りがあると、<font color='red'>正しく補償金のお振込みができません</font>。</p>
              <p>お手数ですが下記の項目について今一度ご確認の上、チェックを入れて次にお進みください。</p>
              <p>※不明な箇所がある場合は、経理等のご担当者様にご確認ください。</p>
            </Grid >

            <Grid item style={{ width: '100%', textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.isAgree1}
                    color='primary'
                  />
                }
                onChange={(e) => props.setIsAgree1(e.target.checked)}
                label={<b>口座名義は、通帳記載の通りにご入力ください。</b>}
              />
            </Grid>

            <Grid item style={{ width: '100%', textAlign: 'left' }}>
              <Stack direction='column'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.isAgree2}
                      color='primary'
                    />
                  }
                  onChange={(e) => props.setIsAgree2(e.target.checked)}
                  label={<b>法人種別（株式会社、社団法人など）が含まれる場合、必ずご入力ください。</b>}
                />
                <p style={{ fontSize: '12px' }}>　※法人種別が含まれない場合もチェックを入れてください。</p>
              </Stack>
            </Grid >

            <Grid item style={{ width: '100%', textAlign: 'left' }}>
              <Stack direction='column'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.isAgree3}
                      color='primary'
                    />
                  }
                  onChange={(e) => props.setIsAgree3(e.target.checked)}
                  label={<>
                    <b>法人種別が含まれる場合、略称でご入力ください。</b>
                    <br />
                    <b>略称については、法人種別略称一覧をご参照ください。</b>
                  </>
                  }
                />
                <p style={{ fontSize: '12px' }}>　※法人種別が含まれない、または略称が含まれない場合もチェックを入れてください。</p>
              </Stack>
            </Grid >
          </Grid >
        </Box>
      </Grid >
    </Grid >
  )
}

export default CorporateAccountInfo