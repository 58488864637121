import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { Context } from '../Content';
import CustomButton from './components/CustomButton';
import { GRAY_FRAME } from './components/colors.js'
import './contents.css';

// プライバシーポリシーボックススタイル
const style = {
	height: 400,
	border: 'solid 1px',
	borderRadius: '4px',
	borderColor: GRAY_FRAME,
	overflow: 'hidden',
	overflowY: 'scroll',
	padding: 1
};

function TermsForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;

	const [isAgree, setIsAgree] = useState(false);
	const [errMessage, setErrMessage] = useState('');

	// ステップ移動時の処理
	const handleTransitionNext = () => {
		if (isAgree) {
			props.handleTransition('/details');
		}
		else {
			setErrMessage('同意されない場合は先へ進むことが出来ません。');
		}
	};

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item alignItems='flex-start' style={{ width: '100%' }}>
				<p>プライバシーポリシーをご確認の上、同意いただきまして次へお進みください。</p>
			</Grid>

			<Grid item>
				<Box sx={{ ...style }}>
					<div style={{ fontSize: '14px' }}>
						<p>一般社団法人授業目的公衆送信補償金等管理協会（以下「本会」といいます。）は、以下に定めるプライバシーポリシー（以下「本ポリシー」といいます。）に基づき個人情報を取り扱い、個人情報の保護に努めます。</p>
						<p>なお、本ポリシーで使用する用語の意味は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に準拠するものとします。</p>
						<br />
						<p>１．法令等の遵守</p>
						<p>本会は、個人情報保護法その他の関係法令及びそのガイドラインを遵守します。</p>
						<br />
						<p>２．個人情報の取得</p>
						<p>本会は、利用目的を明確にし、適法かつ適正な手段を用いて個人情報を取得します。</p>
						<br />
						<p>３．個人情報の利用目的</p>
						<p>本会は、次の目的（以下「本利用目的」といいます。）に必要な範囲において、個人情報を取得及び利用します。</p>
						<p>① 著作権法第１０４条の１１第２項に基づく授業目的公衆送信補償金の徴収及び分配その他補償金を受ける権利の行使のため</p>
						<p>② 著作権又は著作隣接権の管理業務のため</p>
						<p>③ 著作権制度の普及啓発及び調査研究のため</p>
						<p>④ 著作物の創作の振興及び普及のため</p>
						<p>⑤ 著作権及び著作隣接権の保護に関する国際協力のため</p >
						<p>⑥ 教育における著作物等の利用に関する調査研究のため</p >
						<p>⑦ お問合せやご意見、ご要望にお応えするため</p >
						<p>⑧ 本会の業務上、必要なご案内及びご連絡をするため</p >
						<p>⑨ 本会の行う業務の向上又は改善のための調査、分析、研究及び開発のため</p >
						<p>上記の利用目的に加えて、本会が個人情報を取得する際に個別に利用目的を通知又は公表した場合には、その利用目的のためにも個人情報を利用いたします。</p >
						<br />
						<p>４．個人情報の第三者提供</p>
						<p>（１）本会は、著作権法第１０４条の１１第１項に定める授業目的公衆送信補償金を受ける権利を有する者の特定に必要な場合その他の本利用目的の達成に必要な場合、個人情報を、本会の会員又はその構成員に対して提供する場合があります。</p >
						<p>（２）本会は、上記の場合のほか、次に掲げるいずれかの場合を除き、個人情報を第三者に提供いたしません。</p>
						<p>① ご本人から同意を得た場合</p>
						<p>② 法令に基づく場合</p >
						<p>③ 人の生命、身体又は財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合</p >
						<p>④ 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難である場合</p >
						<p>⑤ 国の機関又は地方公共団体若しくはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</p >
						<p>⑥ 当該第三者が学術研究機関等である場合であって、当該第三者が当該個人データを学術研究目的で取り扱う必要がある場合（当該個人データを取り扱う目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）</p >
						<p>⑦ 利用目的の達成の範囲内において外部業者その他の第三者に個人情報の取扱いを委託する場合</p >
						<p>⑧ 合併等により事業が承継され、個人情報が提供される場合</p >
						<p>⑨ 個人情報の共同利用に伴い個人情報が提供される場合</p >
						<p>（３）本会は、外国にある第三者へ個人情報の提供を行う場合、法令の定めに従い、ご本人から同意を取得する等の必要な措置を講じた上で提供いたします。</p>
						<br />
						<p>５．個人情報の取扱いの委託</p>
						<p>本会は、本利用目的の達成に必要な範囲で、個人情報の取扱いに関する業務を外部委託することがあります。個人情報の取扱いに関する業務の外部委託を行う場合は、委託先に対して必要かつ適切な監督を行います。</p >
						<br />
						<p>６．個人情報の共同利用</p>
						<p>本会は、個人情報を以下のとおり共同利用します。</p >
						<p>（１）　共同利用する個人データの項目</p >
						<p>本会の事業に関連してご本人から提供される一切の個人情報</p >
						<p>（２）　共同利用者の範囲</p >
						<p>本会の会員</p >
						<p>（３）　共同利用者の利用目的</p >
						<p>本利用目的</p >
						<p>（４）　共同利用における管理責任者</p >
						<p>本会一般社団法人授業目的公衆送信補償金等管理協会</p >
						<p>本会の概要につきましては、以下のリンク先をご参照ください。</p >
						<p>・https://sartras.or.jp/outline/</p>
						<p>・https://sartras.or.jp/yakuintou/</p >
						<br />
						<p>７．個人情報の保護</p>
						<p>本会は、個人情報の漏洩、減失又は毀損の防止その他の個人情報の安全管理のために、必要かつ適切な措置を講じます。</p >
						<p>本会における個人情報の安全管理のために講じた措置の内容については、「１０．お問合せ先」記載のお問合せ窓口までお問い合わせください。</p >
						<br />
						<p>８．個人情報の開示等の手続き</p>
						<p>本会が保有する個人情報について、ご本人から利用目的の通知、開示、訂正、追加、削除、利用停止、消去若しくは第三者への提供停止又は第三者提供記録の開示（以下「開示等」といいます。）を求められたときは、速やかに対応し、開示等のご請求に理由があると認められるときは、所定の手続きにより開示します。ただし、法令の定めにより本会が開示等の義務を負わない場合は、ご請求に応じられない場合があります。</p >
						<p>本会が保有する個人情報の開示等に関するお問合せは「１０．お問合せ先」に記載のお問合せ窓口へご連絡ください。</p >
						<br />
						<p>９．プライバシーポリシーの変更</p>
						<p>本会は、本プライバシーポリシーの内容を必要に応じて変更することがあります。</p >
						<br />
						<p>１０．お問合せ先</p>
						<p>ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問合せ及び個人情報の開示等につきましては、下記お問合せ窓口にて承ります。</p >
						<p>一般社団法人 授業目的公衆送信補償金等管理協会</p>
						<p>TEL 03 - 6381 - 5026（平日9: 30～12:00、13:00～17: 30）</p >
						<p>FAX 03 - 6381 - 5027</p >
						<p>otoiawase＠sartras.or.jp</p >
						<br />
						<p>2021年3月18日制定</p>
						<p>2022年8月18日制定</p >
					</div >
				</Box >
			</Grid >

			<Grid item style={{ width: '100%', textAlign: 'center' }}>
				<FormControlLabel
					control={
						<Checkbox
							checked={isAgree}
							color='primary'
							inputProps={{ 'data-testid': 'terms-checkbox' }}
						/>
					}
					onChange={(e) => setIsAgree(e.target.checked)}
					label='同意する'
				/>
				<p className='errorMessage'>{errMessage !== '' ? errMessage : ''}</p>
			</Grid>

			<Grid item>
				<CustomButton
					label='次へ'
					variant='contained'
					onClick={handleTransitionNext}
					dataTestId='terms-nextbutton'
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/auth')}
				/>
			</Grid>
		</Grid >
	)
}

export default TermsForm