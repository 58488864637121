import React, { useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { convertToHalfWidthAccountName, UNMATCHED_ACCOUNT_NAME_REGEX } from './converter.js';

// 口座名義入力ボックス
function InputAccountNameBox(props) {
  const isError = props.errMessage !== undefined && props.errMessage !== '';
  const [isInputZenkaku, setIsInputZenkaku] = useState(false);

  // リアルタイム入力判定
  const handleChange = (value) => {
    if (isInputZenkaku) {
      // 全角入力中は自由に入力できる
      props.setValue(value);
    }
    else {
      // 半角入力中は禁止文字は打てない
      props.setValue(value.replace(UNMATCHED_ACCOUNT_NAME_REGEX, ''));
    }
  }

  // ペースト時の処理
  const handlePaste = (event) => {
    event.preventDefault();
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;
    const str = props.value.substring(0, selectionStart) + event.clipboardData.getData('text/plain') + props.value.substring(selectionEnd, props.value.length);
    props.setValue(convertToHalfWidthAccountName(str));
  }

  return (
    <FormControl name={props.name} error={isError} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <FormLabel sx={{ color: '#1a1a1a' }} style={{ display: props.label.match(/\S/g) ? 'block' : 'none' }}>
        <Stack direction='row'>
          {props.label}
        </Stack>
      </FormLabel>
      <TextField
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => handleChange(e.target.value)}
        onPaste={(e) => handlePaste(e)}
        onCompositionStart={() => setIsInputZenkaku(true)}
        onCompositionEnd={(e) => {
          setIsInputZenkaku((prevState) => !prevState);
          props.setValue(convertToHalfWidthAccountName(e.target.value));
        }}
        onBlur={(e) => props.setValue(convertToHalfWidthAccountName(e.target.value))}
        error={isError}
        variant='outlined'
        size='small'
        inputProps={{ inputMode: props.type }}
      />
      <div style={{ display: props.isUseHelperText ? 'block' : 'none' }}>
        <FormHelperText id='component-error-text'>
          {props.errMessage}
        </FormHelperText>
      </div>
    </FormControl>
  )
}

export default InputAccountNameBox