import Validator from 'validatorjs'

// 口座名義の正規表現
export const ACCOUNT_NAME_REGEX = '0-9A-Za-z｢｣ｦ-ｯｱ-ﾟ \\(\\)\\.\\/\\-';

// 数字のみの正規表現
export const NUMBER_ONLY_REGEX = '0-9０-９';

// 全角カタカナルール
Validator.register(
  'zenkakuKana',
  value => value.match(/^[ァ-ヶー　]+$/)
);

// 口座名義ルール
Validator.register(
  'accountName',
  value => value.match(new RegExp(`^[${ACCOUNT_NAME_REGEX}]`, 'g'))
);

// 半角数字のみルール（常設のnumericだと文字数制限と競合するため）
Validator.register(
  'hankakuNumber',
  value => value.match(new RegExp('^[0-9]', 'g'))
);

// バリデーションルール
const allRules = {
  copyrightHolderId: 'required',
  code: 'required|string',
  firstName: 'required',
  lastName: 'required',
  firstNameFurigana: 'required|zenkakuKana',
  lastNameFurigana: 'required|zenkakuKana',
  email: 'required|email',
  phoneNumber: 'required|numeric',
  postalCode: 'required|string|size:7|hankakuNumber',
  region: 'required',
  city: 'required',
  address1: 'required',
  bankCode: 'required',
  branchId: 'required',
  accountNumber: 'required|string|max:8|hankakuNumber',
  accountSei: 'required|accountName',
  accountMei: 'accountName',
  corporateNumber: 'string|size:13|hankakuNumber',
  name: 'required',
  nameFurigana: 'required|zenkakuKana',
  representativeName: 'required',
  representativeNameFurigana: 'required|zenkakuKana',
  contactDepartment: 'required',
  contactName: 'required',
  contactNameFurigana: 'required|zenkakuKana',
  contactEmail: 'required|email',
  contactPhoneNumber: 'numeric',
  accountName: 'required|accountName',
}

// エラーメッセージ
const customErrorMessages = {
  required: '必須項目です。',
  zenkakuKana: '全角カタカナで入力してください。',
  email: 'メールアドレスの形式が正しくありません。',
  numeric: '半角数字で入力してください。',
  size: ':size桁で入力してください。',
  max: ':max桁以下の数字を入力してください。',
  accountName: '入力できない文字が含まれています。',
  hankakuNumber: '半角数字で入力してください。'
}

// 適切なエラーメッセージ連想配列に変換する
const generateErrMessasge = (value) => {
  const errMessage = {};
  for (const key in value) {
    errMessage[key] = value[key][0];
  }
  return errMessage;
}

// 必要なルールを取り出す
const takeOutRules = (value) => {
  const rules = {};
  for (const key in value) {
    if (key in allRules) rules[key] = allRules[key];
  }
  return rules;
}

// バリデーションチェック関数本体
export const validateInputs = (data) => {
  // 必要なルールを取得
  const rules = takeOutRules(data);
  // バリデーションチェック
  const validation = new Validator(data, rules, customErrorMessages);
  // バリデーション結果とエラー文を返す  
  return [validation.fails(), generateErrMessasge(validation.errors.all())];
}

// 追加認証IDの重複チェック関数
export const duplicateValidateInputs = (data) => {
  const processedIds = new Set();
  const duplicateErrMessage = {};
  data.forEach((item, i) => {
    const itemId = item.copyright_holder_id;
    if (processedIds.has(itemId)) {
      // 初回入力index分-1
      duplicateErrMessage[i - 1] = '権利者IDが重複しています。'
    }
    else {
      processedIds.add(itemId);
    }
  });
  return [Object.keys(duplicateErrMessage).length > 0, duplicateErrMessage];
}