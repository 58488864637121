import { scroller } from 'react-scroll';

const options = {
  duration: 500,
  smooth: true,
  offset: -150
};

export const smoothScroll = (name) => {
  scroller.scrollTo(name, options);
}