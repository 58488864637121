import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';

// 通常の入力テキストボックス
function InputTextBox(props) {
  const isError = props.errMessage !== undefined && props.errMessage !== '';
  const dataTestId = props.dataTestId;
  return (
    <FormControl name={props.name} error={isError} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <FormLabel sx={{ color: '#1a1a1a' }}>
        <Stack direction='row'>
          {props.label}
          <div style={{ display: props.isUseNecessity ? 'block' : 'none' }}>
            <div style={{ display: props.isRequired ? 'block' : 'none' }}>
              <div className='required'>必須</div>
            </div>
            <div style={{ display: !props.isRequired ? 'block' : 'none' }}>
              <div className='optional'>任意</div>
            </div>
          </div>
        </Stack>
      </FormLabel>
      <TextField
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyUp={e => props.onKeyUp ? props.onKeyUp(e) : null}
        error={isError}
        variant='outlined'
        size='small'
        inputProps={{ inputMode: props.type, 'data-testid': dataTestId }}
      />
      <div style={{ display: props.isUseHelperText ? 'block' : 'none' }}>
        <FormHelperText id='component-error-text'>
          {props.errMessage}
        </FormHelperText>
      </div>
    </FormControl>
  )
}

export default InputTextBox