import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Context } from '../Content';
import CustomButton from './components/CustomButton'
import InputTextBox from './components/InputTextBox'
import InputCodeBox from './components/InputCodeBox'
import { GRAY_FRAME } from './components/colors.js'
import { validateInputs } from './components/validateInputs.js';

// よくあるご質問のボックススタイル
const style = {
	height: 'auto',
	borderTop: 'solid 1px',
	borderColor: GRAY_FRAME,
	padding: 1,
};

function AuthForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;
	const { isCorrectTransition, setIsCorrectTransition } = isCorrectTransitionContext;

	const [copyrightHolderId, setCopyrightHolderId] = useState('');
	const [code, setCode] = useState('');
	const [errMessage, setErrMessage] = useState({});

	// 初期化
	const InitFunc = () => {
		// URLパラメータが存在すれば埋め込む
		let url = new URL(window.location.href);
		let params = url.searchParams;
		setCopyrightHolderId(params.get('copyright_holder_id') == null ? '' : params.get('copyright_holder_id'));
		setCode(params?.get('code') == null ? '' : params?.get('code'));

		// inputTypeの初期化を済ませておく
		setInputData({ ...inputData, 'TypeForm': { 'inputType': '0' } });
	}

	// ステップ移動時の処理
	const handleTransitionNext = () => {
		const json = { copyrightHolderId, code };
		const [isErr, message] = validateInputs(json);
		setErrMessage({});
		if (isErr) {
			setErrMessage(message);
		}
		else {
			axios
				.post(
					process.env.REACT_APP_API_DOMAIN + '/v1/authenticate/',
					[{
						copyright_holder_id: json.copyrightHolderId,
						code: json.code
					}]
				)
				.then(res => {
					setInputData({
						...inputData,
						'AuthForm': { copyrightHolderId, code },
						'Details': res.data
					});
					props.handleTransition('terms');
				})
				.catch(err => {
					setErrMessage({ result: err.response?.data[copyrightHolderId] });
				});
		}
	};

	// ページ離脱時に認証画面へ移動
	useEffect(() => {
		if (inputData.length !== 0) {
			window.location.assign('/auth');
		}
		InitFunc();
	}, [])

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item alignItems='flex-start' style={{ width: '100%' }}>
				<p>SARTRASは著作権法第35条及び第104条の11に基づき、「授業目的公衆送信補償金制度」（以下、「本制度」）のもとで著作物を利用された権利者の皆様に、補償金の分配を行います。SARTRAS又は分配業務受託団体（以下、「分配業務受託団体等」）が振込先情報を有していない場合は、「権利者ID」及び「認証コード」（以下、「権利者ID等」）を通知の上、振込に必要な情報の登録をお願いしております。</p>
				<br />
				<p>通知のあった権利者ID等でログインいただき、振込先として指定する銀行口座等をご登録ください。</p>
				<p>※権利者ID等を複数組お持ちの場合は、いずれか一組を入力してください。他の権利者ID等は、後の画面でまとめて登録できます。</p>
			</Grid>

			<Grid item style={{ width: '100%', textAlign: 'center' }}>
				<InputTextBox
					id='copyright_holder_id'
					label='権利者ID'
					value={copyrightHolderId}
					onChange={setCopyrightHolderId}
					errMessage={errMessage?.copyrightHolderId}
					isUseNecessity={false}
					isUseHelperText={true}
				/>
			</Grid>

			<Grid item style={{ width: '100%', textAlign: 'center' }}>
				<InputCodeBox
					id='code'
					label='認証コード'
					value={code}
					onChange={setCode}
					errMessage={errMessage?.code}
					isUseNecessity={false}
					isUseHelperText={true}
				/>
			</Grid>

			<Grid item className='errorMessage'>
				<p>{errMessage?.result !== '' ? errMessage?.result : ''}</p>
			</Grid>

			<Grid item>
				<CustomButton
					label='登録開始'
					variant='contained'
					color='primary'
					onClick={handleTransitionNext}
					dataTestId='auth-nextbutton'
				/>
			</Grid>

			<Grid item sx={{ fontSize: 40, fontFamily: 'Arial Black', color: '#4169e1', marginBottom: -2, marginTop: 5 }}>
				<p>F A Q</p>
			</Grid>

			<Grid item sx={{ marginBottom: -2 }}>
				<p>よくあるご質問</p>
			</Grid>

			<Grid item>
				<Box sx={{ ...style }}>
					<p><b>Q1. 「授業目的公衆送信補償金制度」とは何ですか</b></p>
					<p>A1. 2020年度に施行された改正著作権法第35条により、幼稚園から大学、その他社会教育施設までを含めたいわゆる教育機関では、その設置者（教育委員会、学校法人等）が授業目的公衆送信補償金（以下「補償金」といいます。）を当協会にお支払いいただくことで、授業を行う教員や児童、生徒、学生等が必要と認められる限度で且つ権利者の利益を不当に害さない範囲であれば、公表されている国内外著作物について、その権利者の許諾を得ずに公衆送信（インターネット送信等）することが可能になりました。</p>
					<p>この補償金は、2020年度に限り新型コロナウイルス感染拡大の状況に鑑み、無償化されましたが、2021年度からは、文化庁長官より指定を受け、この補償金の収受と分配を担う国内唯一の指定管理団体である当協会（一般社団法人授業目的公衆送信補償金等管理協会SARTRAS（サートラス））が収受し、分配することとなっております。</p>
					<br />
					<p><b>Q2. 補償金の分配に関する案内をもらって驚いています。受け取ってもよいのでしょうか。</b></p>
					<p>A2. 本制度の下では、教育機関で著作権法第35条に基づく公衆送信利用が行われますと、特段の契約や委託の関係がなくても、利用された著作物の権利者が補償金を受領する権利を有することとなります。</p>
					<p>今回ご案内がお手元に届いたということは、あなたの著作物が教育機関で利用されたという報告がSARTRAS宛に寄せられたということです。安心してお受け取りください。</p>
					<br />
					<p><b>Q3. 利用された著作物はどのようにして判明したのですか</b></p>
					<p>A3. SARTRASは、本制度をご利用いただいている全国教育機関からサンプル抽出した教育機関に授業目的公衆送信を行った著作物について報告をいただくよう依頼し、提出を受けております。そして、この報告に記載の著作物を補償金の分配対象としております。</p>
					<p>サンプル抽出してお願いしております理由は、本制度の実施にあたり、教育機関に報告負荷をできるだけかけないよう配慮を求められたことが大きな理由です。</p>
					<br />
					<p><b>Q4. 著作物を利用した教育機関について教えてください</b></p>
					<p>A4. SARTRASと教育機関設置者とのお約束により、個別の教育機関名へのお問い合わせにつきましてはお答えしかねます。何卒ご理解いただきますようお願いいたします。</p>
					<br />
					<p><b>Q5. どのような方法で利用されたのか教えてください</b></p>
					<p>A5. 例えばオンライン授業中、先生が生徒に授業に用いる目的で、著作物を必要な量だけ適正に公衆送信利用した場合がこの制度の対象となります。</p>
					<p>なお、個別具体的な情報までは当協会でも持ち合わせておりません。ご了承ください。</p>
					<br />
					<p><b>Q6. 補償金の受け取りを辞退したら、その補償金はどうなりますか</b></p>
					<p>A6. 受け取りを辞退された補償金は、翌年度の分配基金に組み入れ、権利者の皆様へ分配させていただきます。</p>
					<br />
					<br />
					<p>お問合せ先</p>
					<p>一般社団法人 授業目的公衆送信補償金等管理協会（SARTRAS）</p>
					<p>bunpai＠sartras.or.jp</p>
					<p>TEL 03-6381-5026（平日9:30～12:00、13:00～17:30）</p>
					<p>FAX 03-6381-5027</p>
					<br />
				</Box>
			</Grid>
		</Grid >
	)
}

export default AuthForm