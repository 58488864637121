import Grid from '@mui/material/Grid';
import CustomButton from './components/CustomButton'

function ErrorForm(props) {
	return (
		<Grid container alignItems='center' justifyContent='center'>
			<Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
				エラーが発生しました。<br />
				お手数をおかけしますが、はじめからやり直してください。
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/auth')}
				/>
			</Grid>
		</Grid>
	)
}

export default ErrorForm