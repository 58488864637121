import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import InputInvoiceNumberBox from './InputInvoiceNumberBox';
import { convertToHENumbers } from './converter.js';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  border: 'solid 1px',
  borderRadius: '4px',
  borderColor: '#1976D2',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

// インボイス入力ダイアログ
function InputInvoiceModal(props) {
  const [inputNumber, setInputNumber] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCharLimitError, setIsCharLimitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSearch = (searchNumber) => {
    setSearchResult(null);
    setIsSearched(false);
    setIsCharLimitError(false);
    setIsChecked(false)
    if (searchNumber.length !== 13) {
      setIsCharLimitError(true);
    }
    else {
      setIsLoading(true);
      axios
        .get(process.env.REACT_APP_API_DOMAIN + '/v1/invoice/T' + searchNumber)
        .then(res => {
          setIsLoading(false);
          setIsSearched(true);
          setSearchResult(res.data);
        })
        .catch(err => {
          setIsLoading(false);
          setIsSearched(true);
        });
    }
  };

  const handleKeyPress = (e) => {
    if (!e.nativeEvent.isComposing && e.key === 'Enter') {
      const inputNumber = convertToHENumbers(e.target.value);
      setInputNumber(inputNumber);
      handleClickSearch(inputNumber);
    }
  }

  const handleClose = () => {
    setInputNumber('');
    props.handleClose();
  }

  useEffect(() => {
    // inputNumber変更時に検索結果をリセットする
    setIsSearched(false);
    setIsCharLimitError(false);
    setIsChecked(false)
  }, [inputNumber])

  return (
    <Box sx={{ ...style }}>
      <Stack direction='row' justifyContent='flex-end' style={{ marginTop: 0, marginRight: 0 }}>
        <IconButton
          onClick={handleClose}
          size='small'
        >
          <CloseIcon sx={{ color: '#D32F2F' }} />
        </IconButton>
      </Stack>
      <Grid container spacing={1} alignItems='center' justifyContent='center'>
        <Grid item xs={12}>
          <p>登録番号を入力後、表示される情報をお確かめください。</p>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <p>登録番号</p>
        </Grid>
        <Grid item xs={1}>
          <p style={{ fontSize: '32px', marginLeft: '10px' }}>T</p>
        </Grid>
        <Grid item xs={5}>
          <InputInvoiceNumberBox
            value={inputNumber}
            onChange={setInputNumber}
            onKeyUp={handleKeyPress}
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            variant='contained'
            onClick={() => handleClickSearch(inputNumber)}
            fullWidth
          >
            検索
          </Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              border: 'solid 1px',
              borderRadius: '8px',
              borderColor: '#5c5c5c',
              display: 'flex',
              flexWrap: 'wrap',
              bgcolor: 'background.paper',
              maxWidth: 800,
              height: 240
            }}
          >
            <Box style={{ borderBottom: 'solid 1px', borderColor: '#5c5c5c', height: '20px', width: '100%', padding: '8px' }}>
              <p>登録番号の情報</p>
            </Box>

            <Box style={{ height: '220px', width: '100%', padding: '8px' }}>
              <span style={{ display: searchResult !== null && isSearched ? 'block' : 'none' }}>
                名前：　{searchResult?.name}<br />
                住所：　{searchResult?.address}
                <div style={{ fontSize: '12px', marginTop: '5px' }}>
                  異なる法人の情報が表示された場合には、登録番号をお確かめください。
                </div>
              </span>

              <span style={{ display: isCharLimitError ? 'block' : 'none' }}>
                <div style={{ fontSize: '14px', color: 'red' }}>
                  Tを除く13桁の番号を入力してください。
                </div>
              </span>

              <span style={{ display: searchResult === null && isSearched ? 'block' : 'none' }} >
                <div style={{ fontSize: '14px', color: 'red' }}>
                  該当する情報が見つかりませんでした。<br />
                  登録時期などによって情報が表示されない場合もございますので、登録番号にお間違いがないことを確認いただけましたら、以下のチェックを入れてから入力を完了してください。
                </div>
                <br />
                <br />
                <div style={{ fontSize: '14px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        color='primary'
                      />
                    }
                    onChange={(e) => setIsChecked(e.target.checked)}
                    label='上記の登録番号で間違いないことを確認した'
                  />
                </div>
              </span>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '-250px', pointerEvents: 'none' }}>
          <Fade in={isLoading}>
            <CircularProgress />
          </Fade>
        </Grid>

        <Grid item xs={12} style={{ fontSize: '10px' }}>
          ※当該検索サービスは国税庁法人番号システムのWeb-API機能を利用して取得した情報をもとに作成されています。サービスの内容は国税庁によって保証されたものではありません。
        </Grid>
        <Grid item xs={12}>
          <Button variant='outlined' disabled={!isSearched || (searchResult === null && !isChecked)} onClick={() => props.handleConfirm(inputNumber)} >
            上記を確認して、入力を完了する
          </Button>
        </Grid>
      </Grid>
    </Box >
  );
};

export default InputInvoiceModal