import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

// 明細を表示するコンポーネント
function ContentsDetailTable(props) {
  const keyArr = Object.keys(props.data[0]);
  return (
    <React.Fragment>
      <span>
        権利者ID：{props.id}の明細
      </span>
      <TableContainer>
        <Table size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              {keyArr.map((key, index) => (
                <TableCell sx={{ minWidth: 100 }}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((data, index) => (
              <TableRow>
                {keyArr.map((key, index) => (
                  <TableCell sx={{ minWidth: 100 }}>{data[key].value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ContentsDetailTable