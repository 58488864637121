import React, { useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';

// メールアドレス入力テキストボックス
function InputEmailBox(props) {
  return (
    <FormControl name={props.name} error={props.errMessage !== undefined && props.errMessage !== ''} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <Stack direction='row'>
        <FormLabel sx={{ color: '#1a1a1a' }}>
          <Stack direction='row'>
            <p>{props.label}</p>
            <p className='required'>必須</p>
      </Stack>
    </FormLabel>
      </Stack >
      <TextField
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={e => props.onChange(e.target.value)}
        error={props.errMessage !== undefined && props.errMessage !== ''}
        variant='outlined'
        size='small'
        InputProps={{ inputMode: 'email' }}
      />
      <FormHelperText id='component-error-text'>
        <p>{props.errMessage}</p>
      </FormHelperText>
    </FormControl >
  )
}

export default InputEmailBox