import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton'
import CorporateContactInfo from '../components/CorporateContactInfo';
import { validateInputs } from '../components/validateInputs.js';
import { smoothScroll } from '../components/smoothScroll.js';

function AuthorizationContactInfoForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;

	const [contactDepartment, setContactDepartment] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].contactDepartment);
	const [contactName, setContactName] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].contactName);
	const [contactNameFurigana, setContactNameFurigana] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].contactNameFurigana);
	const [contactEmail, setContactEmail] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].contactEmail);
	const [contactPhoneNumber, setContactPhoneNumber] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].contactPhoneNumber);
	const [contactAddressType, setContactAddressType] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '0' : inputData['AuthorizationContactInfoForm'].contactAddressType);
	const [postalCode, setPostalCode] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].postalCode);
	const [region, setRegion] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].region);
	const [city, setCity] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].city);
	const [address1, setAddress1] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].address1);
	const [address2, setAddress2] = useState(inputData['AuthorizationContactInfoForm'] === undefined ? '' : inputData['AuthorizationContactInfoForm'].address2);
	const [errMessage, setErrMessage] = useState([]);

	// ステップ移動時の処理
	const handleTransitionNext = () => {
		const json = { contactDepartment, contactName, contactNameFurigana, contactEmail, contactPhoneNumber, contactAddressType, postalCode, region, city, address1, address2 };
		const [isErr, message] = contactAddressType === '0' ? validateInputs(json) : validateInputs({ contactDepartment, contactName, contactNameFurigana, contactEmail, contactPhoneNumber });
		setErrMessage([]);
		if (isErr) {
			setErrMessage(message);
			smoothScroll(Object.keys(message)[0]);
		}
		else {
			setInputData({ ...inputData, 'AuthorizationContactInfoForm': json });
			props.handleTransition('/others/authorization/accountinfo');
		}
	};

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item alignItems='flex-start' style={{ width: '100%' }}>
				<p>担当者名と連絡先をご入力ください。</p>
			</Grid>

			<Grid item style={{ width: '100%', textAlign: 'center' }}>
				<CorporateContactInfo
					contactDepartment={contactDepartment}
					contactName={contactName}
					contactNameFurigana={contactNameFurigana}
					contactEmail={contactEmail}
					contactPhoneNumber={contactPhoneNumber}
					contactAddressType={contactAddressType}
					postalCode={postalCode}
					region={region}
					city={city}
					address1={address1}
					address2={address2}
					setContactDepartment={setContactDepartment}
					setContactName={setContactName}
					setContactNameFurigana={setContactNameFurigana}
					setContactEmail={setContactEmail}
					setContactPhoneNumber={setContactPhoneNumber}
					setContactAddressType={setContactAddressType}
					setPostalCode={setPostalCode}
					setRegion={setRegion}
					setCity={setCity}
					setAddress1={setAddress1}
					setAddress2={setAddress2}
					errMessage={errMessage}
					setErrMessage={setErrMessage}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='次へ'
					variant='contained'
					onClick={handleTransitionNext}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/others/authorization/basicinfo')}
				/>
			</Grid>
		</Grid>
	)
}

export default AuthorizationContactInfoForm