import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CustomButton from './CustomButton';
import ContentsDetailModal from './ContentsDetailModal';
import ContentsDetailDocument from './ContentsDetailDocument';

const modalStyles = {
  content: {
    top: '10%',
    left: '10%',
    right: '10%',
  },
};

const PDF_NAME = '利用報告明細';

// 明細表示コンポーネント
function DetailComponent(props) {

  const [target, seTarget] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ids = Object.keys(props.data);
  const amounts = {};
  let totalAmount = 0;

  ids.forEach(id => {
    let total = 0;
    props.data[id].forEach(element => {
      total += parseInt(element['金額'].value);
    })
    amounts[id] = total;
    totalAmount += total;
  });

  // 確認ボタン押下
  const handleClickCheck = (id) => {
    seTarget([{ 'id': id, 'data': props.data[id] }]);
    setIsModalOpen(true);
  }

  // 一括確認ボタン押下
  const onClickAllCheck = () => {
    const arr = [];
    ids.forEach(id => {
      arr.push({ 'id': id, 'data': props.data[id] })
    });
    seTarget(arr);
    setIsModalOpen(true);
  }

  // PDF出力する明細表コンポーネントを返す
  const returnPage = (id) => {
    let items = [];
    if (id === '') {
      // 一括DL
      ids.forEach(id => {
        items.push({ 'id': id, 'data': props.data[id] })
      });
    }
    else {
      // 個別DL
      items.push({ 'id': id, 'data': props.data[id] });
    }
    return (
      <ContentsDetailDocument items={items} />
    )
  }

  // モーダルクローズ時
  const handleClose = () => {
    setIsModalOpen(false);
  }

  return (
    <Grid container spacing={1} columns={30} alignItems='center' style={{ width: '100%' }}>
      <Grid item xs={6} style={{ width: '100%', textAlign: 'center' }}>
        権利者ID
      </Grid>
      <Grid item xs={6} style={{ width: '100%', textAlign: 'center' }}>
        金額
      </Grid>
      <Grid item xs={9} fullwidth />
      <Grid item xs={9} fullwidth />

      <Grid item xs={30} fullwidth>
        <Box style={{ border: 'solid 1px', borderLeft: '0px', borderRight: '0px', borderBottom: '0px' }} />
      </Grid>

      {ids.map((id, index) => (
        <React.Fragment>
          <Grid item xs={6} style={{ width: '100%', textAlign: 'center' }}>
            {id}
          </Grid>
          <Grid item xs={6} style={{ width: '100%', textAlign: 'center' }}>
            {'￥' + Number(amounts[id]).toLocaleString()}
          </Grid>
          <Grid item xs={9} style={{ width: '100%', textAlign: 'center' }}>
            <CustomButton
              label='明細'
              variant='outlined'
              width={80}
              onClick={() => handleClickCheck(id)}
            />
          </Grid>
          <Grid item xs={9} style={{ width: '100%', textAlign: 'center' }}>
            <span style={{ display: props.isAdditional ? 'block' : 'none' }}>
              <PDFDownloadLink document={returnPage(id)} fileName={PDF_NAME + "（" + id + "）.pdf"} style={{ textDecoration: "none", color: "#0000" }}>
                <CustomButton
                  label="DL"
                  variant="outlined"
                  width={80}
                />
              </PDFDownloadLink>
            </span>
          </Grid>
        </React.Fragment>
      ))}

      <Grid item xs={30} fullwidth>
        <Box style={{ border: 'solid 1px', borderLeft: '0px', borderRight: '0px', borderBottom: '0px' }} />
      </Grid>

      <Grid item xs={6} style={{ width: '100%', textAlign: 'center' }}>
        合計
      </Grid>
      <Grid item xs={6} style={{ width: '100%', textAlign: 'center' }}>
        ￥{Number(totalAmount).toLocaleString()}
      </Grid>
      <Grid item xs={9} style={{ width: '100%', textAlign: 'center' }}>
        <span style={{ display: props.isAdditional ? 'block' : 'none' }}>
          <CustomButton
            label="一括確認"
            variant="outlined"
            width={90}
            onClick={onClickAllCheck}
          />
        </span>
      </Grid>
      <Grid item xs={9} style={{ width: '100%', textAlign: 'center' }}>
        <span style={{ display: props.isAdditional ? 'block' : 'none' }}>
          <PDFDownloadLink document={returnPage('')} fileName={PDF_NAME + "（一括）.pdf"} style={{ textDecoration: "none", color: "#0000" }}>
            <CustomButton
              label="一括DL"
              variant="outlined"
              width={90}
            />
          </PDFDownloadLink>
        </span>
      </Grid>
      <Modal
        hideBackdrop
        open={isModalOpen}
        style={modalStyles}
      >
        <ContentsDetailModal
          target={target}
          onClose={handleClose}
        />
      </Modal>
    </Grid>
  )
}

export default DetailComponent