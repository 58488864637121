import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton'
import CorporateBasicinfo from '../components/CorporateBasicinfo';
import { validateInputs } from '../components/validateInputs.js';
import { smoothScroll } from '../components/smoothScroll.js';

function AuthorizationBasicInfoForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;

	const [corporateNumber, setCorporateNumber] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].corporateNumber);
	const [name, setName] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].name);
	const [nameFurigana, setNameFurigana] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].nameFurigana);
	const [postalCode, setPostalCode] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].postalCode);
	const [region, setRegion] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].region);
	const [city, setCity] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].city);
	const [address1, setAddress1] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].address1);
	const [address2, setAddress2] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].address2);
	const [representativeName, setRepresentativeName] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].representativeName);
	const [representativeNameFurigana, setRepresentativeNameFurigana] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].representativeNameFurigana);
	const [invoiceNumber, setInvoiceNumber] = useState(inputData['AuthorizationBasicInfoForm'] === undefined ? '' : inputData['AuthorizationBasicInfoForm'].invoiceNumber);
	const [errMessage, setErrMessage] = useState('');

	// ステップ移動時の処理
	const handleTransitionNext = () => {
		const json = { corporateNumber, name, nameFurigana, postalCode, region, city, address1, address2, representativeName, representativeNameFurigana, invoiceNumber };
		const [isErr, message] = validateInputs(json);
		setErrMessage([]);
		if (isErr) {
			setErrMessage(message);
			smoothScroll(Object.keys(message)[0]);
		}
		else {
			setInputData({ ...inputData, 'AuthorizationBasicInfoForm': json });
			props.handleTransition('/others/authorization/contactinfo');
		}
	};

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item alignItems='flex-start' style={{ width: '100%' }}>
				<p>法人名や法人登記法上の所在地等をご入力ください。</p>
				<p><b><u>※担当者の連絡先や住所は、次の画面でご入力いただきます。</u></b></p>
			</Grid>

			<Grid item style={{ width: '100%', textAlign: 'center' }}>
				<CorporateBasicinfo
					corporateNumber={corporateNumber}
					name={name}
					nameFurigana={nameFurigana}
					postalCode={postalCode}
					region={region}
					city={city}
					address1={address1}
					address2={address2}
					representativeName={representativeName}
					representativeNameFurigana={representativeNameFurigana}
					invoiceNumber={invoiceNumber}
					setCorporateNumber={setCorporateNumber}
					setName={setName}
					setNameFurigana={setNameFurigana}
					setPostalCode={setPostalCode}
					setRegion={setRegion}
					setCity={setCity}
					setAddress1={setAddress1}
					setAddress2={setAddress2}
					setRepresentativeName={setRepresentativeName}
					setRepresentativeNameFurigana={setRepresentativeNameFurigana}
					setInvoiceNumber={setInvoiceNumber}
					requiredInvoice={true}
					errMessage={errMessage}
					setErrMessage={setErrMessage}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='次へ'
					variant='contained'
					onClick={handleTransitionNext}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/type')}
				/>
			</Grid>
		</Grid>
	)
}

export default AuthorizationBasicInfoForm