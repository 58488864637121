import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';

// スタイルを変更したセルコンポーネント
const CustomCell = styled(TableCell)(() => ({
  textAlign: 'center',
  fontSize: '18px',
  border: '1px solid #BABABA',
}));

// 法人種別略称一覧のテーブルコンポーネント
function ContentsDetailTable() {
  const dataList = require('./CorporatePersonalityAbbreviationData.json');
  return (
    <TableContainer>
      <Table>
        <TableHead style={{ backgroundColor: '#F2F2F2' }}>
          <TableRow>
            <CustomCell rowSpan={2}>法人種別</CustomCell>
            <CustomCell colSpan={3}>略称</CustomCell>
          </TableRow>
          <TableRow>
            <CustomCell>先頭に使うとき</CustomCell>
            <CustomCell>途中に使うとき</CustomCell>
            <CustomCell>末尾に使うとき</CustomCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList.map((data, index) =>
            Array.isArray(data.name) ? (
              // 複数行の場合
              <React.Fragment>
                <TableRow>
                  <CustomCell>{data.name[0]}</CustomCell>
                  <CustomCell rowSpan={data.name.length}>{data.head}</CustomCell>
                  <CustomCell rowSpan={data.name.length}>{data.middle}</CustomCell>
                  <CustomCell rowSpan={data.name.length}>{data.tail}</CustomCell>
                </TableRow>
                {data.name.map((name, index) => {
                  if (index !== 0) {
                    return (
                      <TableRow>
                        <CustomCell>{name}</CustomCell>
                      </TableRow>
                    )
                  };
                })}
              </React.Fragment>
            ) : (
              // 単体行の場合
              <TableRow>
                <CustomCell>{data.name}</CustomCell>
                <CustomCell>{data.head}</CustomCell>
                <CustomCell>{data.middle}</CustomCell>
                <CustomCell>{data.tail}</CustomCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table >
    </TableContainer >
  );
};

export default ContentsDetailTable