import React, { useState, useEffect, createContext } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Logo from './Logo';
import CommonFormat from './CommonFormat';
import AuthForm from './contents/AuthForm';
import TermsForm from './contents/TermsForm';
import TypeForm from './contents/TypeForm';
import DetailsForm from './contents/DetailsForm';

// Type0 forms
import IndividualBasicInfoForm from './contents/type0/IndividualBasicInfoForm';
import IndividualAddressInfoForm from './contents/type0/IndividualAddressInfoForm';
import IndividualAccountInfoForm from './contents/type0/IndividualAccountInfoForm';
import IndividualAdditionalCodeForm from './contents/type0/IndividualAdditionalCodeForm';
import IndividualTotalDetailsForm from './contents/type0/IndividualTotalDetailsForm';
import IndividualRegisterForm from './contents/type0/IndividualRegisterForm';
import IndividualCompletedForm from './contents/type0/IndividualCompletedForm';

// Type1 forms
import CorporateBasicInfoForm from './contents/type1/CorporateBasicInfoForm';
import CorporateContactInfoForm from './contents/type1/CorporateContactInfoForm';
import CorporateAccountInfoForm from './contents/type1/CorporateAccountInfoForm';
import CorporateAdditionalCodeForm from './contents/type1/CorporateAdditionalCodeForm';
import CorporateTotalDetailsForm from './contents/type1/CorporateTotalDetailsForm';
import CorporateRegisterForm from './contents/type1/CorporateRegisterForm';
import CorporateCompletedForm from './contents/type1/CorporateCompletedForm';

// Type2 forms
import AuthorizationBasicInfoForm from './contents/type2/AuthorizationBasicInfoForm';
import AuthorizationContactInfoForm from './contents/type2/AuthorizationContactInfoForm';
import AuthorizationAccountInfoForm from './contents/type2/AuthorizationAccountInfoForm';
import AuthorizationAdditionalCodeForm from './contents/type2/AuthorizationAdditionalCodeForm';
import AuthorizationTotalDetailsForm from './contents/type2/AuthorizationTotalDetailsForm';
import AuthorizationRegisterForm from './contents/type2/AuthorizationRegisterForm';
import AuthorizationCompletedForm from './contents/type2/AuthorizationCompletedForm';

// Type3 forms
import AgentCorporateBasicInfoForm from './contents/type3/AgentCorporateBasicInfoForm';
import AgentCorporateContactInfoForm from './contents/type3/AgentCorporateContactInfoForm';
import AgentCorporateAccountInfoForm from './contents/type3/AgentCorporateAccountInfoForm';
import AgentIndividualBasicInfoForm from './contents/type3/AgentIndividualBasicInfoForm';
import AgentIndividualAddressInfoForm from './contents/type3/AgentIndividualAddressInfoForm';
import AgentAdditionalCodeForm from './contents/type3/AgentAdditionalCodeForm';
import AgentTotalDetailsForm from './contents/type3/AgentTotalDetailsForm';
import AgentRegisterForm from './contents/type3/AgentRegisterForm';
import AgentCompletedForm from './contents/type3/AgentCompletedForm';

import ErrorForm from './contents/ErrorForm';

export const Context = createContext();

function Content() {
	const [inputData, setInputData] = useState([]);
	const inputDataContextValue = { inputData, setInputData };
	const [isCorrectTransition, setIsCorrectTransition] = useState(false);
	const isCorrectTransitionContextValue = { isCorrectTransition, setIsCorrectTransition };
	const [popstate, setPopstate] = useState(false);

	const navigate = useNavigate();

	const selfIndividual = '/self/individual';
	const selfCorporate = '/self/corporate';
	const othersAuthorization = '/others/authorization';
	const othersAgent = '/others/agent';

	// 画面を遷移する処理
	const handleTransition = (url) => {
		setPopstate(false);
		setIsCorrectTransition(true);
		navigate(url);
	};

	// ページ更新（離脱）時のイベントを設定
	useEffect(() => {
		window.addEventListener('beforeunload', onBeforeunload);
		return () => {
			window.removeEventListener('beforeunload', onBeforeunload);
		}
	})

	// ページ更新（離脱）時にダイアログを表示
	const onBeforeunload = (e) => {
		e.preventDefault();
		e.returnValue = '';
	}

	// ブラウザバック禁止処理
	window.history.pushState(null, null, null);
	window.onpopstate = () => {
		window.history.pushState(null, null, null);
		setPopstate(true);
	};

	return (
		<React.Fragment>
			<Snackbar open={popstate} autoHideDuration={6000} onClose={() => setPopstate(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
				<Alert onClose={() => setPopstate(false)} severity='error' sx={{ width: '100%' }}>
					ブラウザの「戻る」を使用することはできません！
				</Alert>
			</Snackbar>

			<Context.Provider value={[inputDataContextValue, isCorrectTransitionContextValue]}>
				<Routes>
					{/* アクセス時は認証画面に飛ばす */}
					<Route path='/' element={<Navigate to='/auth' />} />

					{/* その他の場合はエラー画面に飛ばす */}
					<Route path='/*' element={<Navigate to='/error' />} />

					{/* エラー画面 */}
					<Route path={'/error'} element={<ErrorForm handleTransition={handleTransition} />} />

					{/* 認証画面 */}
					<Route
						path='/auth'
						element={
							<React.Fragment>
								<Logo />
								<AuthForm handleTransition={handleTransition} />
							</React.Fragment>
						}
					/>

					<Route path='/' element={<CommonFormat />}>
						{/* 利用規約画面 */}
						<Route path='/terms' element={<TermsForm handleTransition={handleTransition} />} />

						{/* 明細確認画面 */}
						<Route path='/details' element={<DetailsForm handleTransition={handleTransition} />} />

						{/* 権利者種別選択画面 */}
						<Route path='/type' element={<TypeForm handleTransition={handleTransition} />} />

						{/* self/individual */}
						<Route path={selfIndividual + '/basicinfo'} element={<IndividualBasicInfoForm handleTransition={handleTransition} />} />
						<Route path={selfIndividual + '/addressinfo'} element={<IndividualAddressInfoForm handleTransition={handleTransition} />} />
						<Route path={selfIndividual + '/accountinfo'} element={<IndividualAccountInfoForm handleTransition={handleTransition} />} />
						<Route path={selfIndividual + '/additionalcode'} element={<IndividualAdditionalCodeForm handleTransition={handleTransition} />} />
						<Route path={selfIndividual + '/totaldetails'} element={<IndividualTotalDetailsForm handleTransition={handleTransition} />} />
						<Route path={selfIndividual + '/register'} element={<IndividualRegisterForm handleTransition={handleTransition} />} />
						<Route path={selfIndividual + '/completed'} element={<IndividualCompletedForm />} />

						{/* self/corporate */}
						<Route path={selfCorporate + '/basicinfo'} element={<CorporateBasicInfoForm handleTransition={handleTransition} />} />
						<Route path={selfCorporate + '/contactinfo'} element={<CorporateContactInfoForm handleTransition={handleTransition} />} />
						<Route path={selfCorporate + '/accountinfo'} element={<CorporateAccountInfoForm handleTransition={handleTransition} />} />
						<Route path={selfCorporate + '/additionalcode'} element={<CorporateAdditionalCodeForm handleTransition={handleTransition} />} />
						<Route path={selfCorporate + '/totaldetails'} element={<CorporateTotalDetailsForm handleTransition={handleTransition} />} />
						<Route path={selfCorporate + '/register'} element={<CorporateRegisterForm handleTransition={handleTransition} />} />
						<Route path={selfCorporate + '/completed'} element={<CorporateCompletedForm />} />

						{/* others/authorization */}
						<Route path={othersAuthorization + '/basicinfo'} element={<AuthorizationBasicInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAuthorization + '/contactinfo'} element={<AuthorizationContactInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAuthorization + '/accountinfo'} element={<AuthorizationAccountInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAuthorization + '/additionalcode'} element={<AuthorizationAdditionalCodeForm handleTransition={handleTransition} />} />
						<Route path={othersAuthorization + '/totaldetails'} element={<AuthorizationTotalDetailsForm handleTransition={handleTransition} />} />
						<Route path={othersAuthorization + '/register'} element={<AuthorizationRegisterForm handleTransition={handleTransition} />} />
						<Route path={othersAuthorization + '/completed'} element={<AuthorizationCompletedForm />} />

						{/* others/agent */}
						<Route path={othersAgent + '/corporatebasicinfo'} element={<AgentCorporateBasicInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/corporatecontactinfo'} element={<AgentCorporateContactInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/corporateaccountinfo'} element={<AgentCorporateAccountInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/individualbasicinfo'} element={<AgentIndividualBasicInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/individualaddressinfo'} element={<AgentIndividualAddressInfoForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/additionalcode'} element={<AgentAdditionalCodeForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/totaldetails'} element={<AgentTotalDetailsForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/register'} element={<AgentRegisterForm handleTransition={handleTransition} />} />
						<Route path={othersAgent + '/completed'} element={<AgentCompletedForm />} />
					</Route>
				</Routes>
			</Context.Provider>
		</React.Fragment>
	)
}

export default Content