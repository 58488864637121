import './Header.css';
import React from 'react';
import AppBar from '@mui/material/AppBar';

const Header = () => {
  return (
    <AppBar position='fixed' style={{ padding: '5px', backgroundColor: '#1976D2', height: 60 }}>
      <span className='header' style={{ fontSize: '16px', textAlign: 'center' }}>補償金振込口座情報入力フォーム（MUSUBI）</span>
    </AppBar>
  );
};

export default Header;