import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputInvoiceModal from './InputInvoiceModal';
import InputTextBox from './InputTextBox';
import InputNumberBox from './InputNumberBox';
import InputEmailBox from './InputEmailBox';
import InputReadonlyBox from '../components/InputReadonlyBox';
import { BUTTON_COLOR } from '../components/colors.js'
import searchIcon from '../images/search_icon.png';
import '../contents.css';

function IndividualBasicinfo(props) {
  // Modalのスタイル
  const modalStyles = {
    content: {
      top: '10%',
      left: '10%',
      right: '10%',
    },
  };

  const [isInputInvoiceModalOpen, setIsInputInvoiceModalOpen] = useState(false);

  // インボイスダイアログの確定イベント
  const handleInputInvoiceModalConfirm = (value) => {
    setIsInputInvoiceModalOpen(false);
    props.setInvoiceNumber('T' + value);
  }

  // インボイスダイアログを閉じるイベント
  const handleInputInvoiceModalClose = () => {
    setIsInputInvoiceModalOpen(false);
  }

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='lastName'
          name='lastName'
          label='姓'
          placeholder='授業'
          value={props.lastName}
          onChange={props.setLastName}
          errMessage={props.errMessage.lastName}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='firstName'
          name='firstName'
          label='名'
          placeholder='太郎'
          value={props.firstName}
          onChange={props.setFirstName}
          errMessage={props.errMessage.firstName}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='lastNameFurigana'
          name='lastNameFurigana'
          label='姓（カタカナ）'
          placeholder='ジュギョウ'
          value={props.lastNameFurigana}
          onChange={props.setLastNameFurigana}
          errMessage={props.errMessage.lastNameFurigana}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputTextBox
          id='firstNameFurigana'
          name='firstNameFurigana'
          label='名（カタカナ）'
          placeholder='タロウ'
          value={props.firstNameFurigana}
          onChange={props.setFirstNameFurigana}
          errMessage={props.errMessage.firstNameFurigana}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputEmailBox
          id='email'
          name='email'
          label='連絡先メールアドレス'
          placeholder='example@sartras.or.jp'
          type='email'
          value={props.email}
          onChange={props.setEmail}
          errMessage={props.errMessage.email}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputNumberBox
          id='phoneNumber'
          name='phoneNumber'
          label='連絡先電話番号'
          placeholder='0312345678'
          value={props.phoneNumber}
          onChange={props.setPhoneNumber}
          errMessage={props.errMessage.phoneNumber}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='invoiceNumber' variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a', textAlign: 'Left' }}>
            <p>適格請求書（インボイス）発行事業者登録番号</p>
            <p style={{ fontSize: '10px' }}>※インボイス発行事業者として登録を行っていない場合、入力の必要はありません。</p>
          </FormLabel>
          <Button variant='outlined' color="inherit" sx={BUTTON_COLOR} style={{ marginBottom: 5 }} onClick={() => setIsInputInvoiceModalOpen(true)} >
            <p><img src={searchIcon} align='top' width='20' height='20' />インボイス発行事業者登録番号を入力</p>
          </Button>
          <Modal hideBackdrop open={isInputInvoiceModalOpen} style={modalStyles} >
            <InputInvoiceModal
              handleConfirm={handleInputInvoiceModalConfirm}
              handleClose={handleInputInvoiceModalClose}
            />
          </Modal>
          <Stack direction='row' style={{ width: '100%' }}>
            <span style={{ width: 350 }}>
              <InputReadonlyBox
                value={props.invoiceNumber}
                errMessage={props.errMessage.invoiceNumber}
                isUseHelperText={true}
              />
            </span>
            <span style={{ width: 50, marginTop: 2 }}>
              <IconButton
                onClick={() => props.setInvoiceNumber('')}
                size='small'
              >
                <CloseIcon sx={{ color: '#D32F2F' }} />
              </IconButton>
            </span>
          </Stack>
          <p style={{ fontSize: '12px', color: 'red' }}>
            適格請求書（インボイス）発行事業者登録番号のご入力の有無により、補償金額が異なる場合があります。詳しくは分配業務受託団体等のウェブサイトやお問い合わせによりご確認ください。
          </p>
        </FormControl>
      </Grid>
    </Grid >
  )
}

export default IndividualBasicinfo