import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton'
import SearchBankModal from '../components/SearchBankModal'
import SearchBranchModal from '../components/SearchBranchModal.jsx'
import InputAccountNameBox from '../components/InputAccountNameBox';
import InputNumberBox from '../components/InputNumberBox';
import InputReadonlyBox from '../components/InputReadonlyBox';
import { validateInputs } from '../components/validateInputs.js';
import { BUTTON_COLOR } from '../components/colors.js'
import searchIcon from '../images/search_icon.png';
import { smoothScroll } from '../components/smoothScroll.js';
import '../contents.css';

function IndividualAccountInfoForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [bankName, setBankName] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].bankName);
  const [bankCode, setBankCode] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].bankCode);
  const [branchName, setBranchName] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].branchName);
  const [branchId, setBranchId] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].branchId);
  const [accountNumber, setAccountNumber] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].accountNumber);
  const [accountType, setAccountType] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '1' : inputData['IndividualAccountInfoForm'].accountType.toString());
  const [accountSei, setAccountSei] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].accountSei);
  const [accountMei, setAccountMei] = useState(inputData['IndividualAccountInfoForm'] === undefined ? '' : inputData['IndividualAccountInfoForm'].accountMei);
  const [isSearchBankModalOpen, setIsSearchBankModalOpen] = useState(false);
  const [isSearchBranchModalOpen, setIsSearchBranchModalOpen] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  // Modalのスタイル
  const modalStyles = {
    content: {
      top: '10%',
      left: '10%',
      right: '10%',
    },
  };

  // 銀行名決定イベント
  const onDecideBank = (selection) => {
    setIsSearchBankModalOpen(false);
    setBankName(selection.name);
    setBankCode(selection.code);
    setBranchName('');
    setBranchId('');
  }

  // 支店名決定イベント
  const onDecideBranch = (selection) => {
    setIsSearchBranchModalOpen(false);
    setBranchName(selection.name);
    setBranchId(selection.id)
  }

  // ダイアログ閉じるイベント
  const onClose = () => {
    setIsSearchBankModalOpen(false);
    setIsSearchBranchModalOpen(false);
  }

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    const json = { bankName, bankCode, branchName, branchId, accountType, accountNumber, accountSei, accountMei };
    const [isErr, message] = validateInputs(json);
    setErrMessage([]);
    if (isErr) {
      setErrMessage(message);
      smoothScroll(Object.keys(message)[0]);
    }
    else {
      setInputData({ ...inputData, 'IndividualAccountInfoForm': json });
      props.handleTransition('/self/individual/additionalcode');
    }
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        <p>補償金振込先の銀行口座情報をご入力ください。</p>
        <p>必ずご本人名義の口座をご登録ください。</p>
      </Grid>
      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='bankCode' error={errMessage.bankCode} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              <p>銀行名</p>
              <p className='required'>必須</p>
            </Stack>
          </FormLabel>
          <Button variant='outlined' color="inherit" sx={BUTTON_COLOR} style={{ marginBottom: 5 }} onClick={() => setIsSearchBankModalOpen(true)} >
            <p><img src={searchIcon} align='top' width='20' height='20' />銀行名を検索</p>
          </Button>
          <Modal
            hideBackdrop
            open={isSearchBankModalOpen}
            style={modalStyles}
          >
            <SearchBankModal
              onDecideBank={onDecideBank}
              onClose={onClose}
            />
          </Modal>
          <InputReadonlyBox
            value={bankName}
            errMessage={errMessage.bankCode}
            isUseHelperText={true}
          />
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl name='branchId' error={errMessage.branchId} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              <p>支店名</p>
              <p className='required'>必須</p>
            </Stack>
          </FormLabel>
          <Button variant='outlined' color="inherit" sx={BUTTON_COLOR} style={{ marginBottom: 5 }} onClick={() => setIsSearchBranchModalOpen(true)} >
            <p><img src={searchIcon} align='top' width='20' height='20' />支店名を検索</p>
          </Button>
          <Modal
            hideBackdrop
            open={isSearchBranchModalOpen}
            style={modalStyles}
          >
            <SearchBranchModal
              bankCode={bankCode}
              onDecideBranch={onDecideBranch}
              onClose={onClose}
            />
          </Modal>
          <InputReadonlyBox
            value={branchName}
            errMessage={errMessage.branchId}
            isUseHelperText={true}
          />
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <Grid container>
            <p>預金種類</p>
            <p className='required'>必須</p>
          </Grid>
          <RadioGroup
            defaultValue='1'
            name='accountType'
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <FormControlLabel value='1' control={<Radio color='primary' />} label='普通' />
            <FormControlLabel value='2' control={<Radio color='primary' />} label='当座' />
            <FormControlLabel value='4' control={<Radio color='primary' />} label='貯蓄' />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <InputNumberBox
          id='accountNumber'
          name='accountNumber'
          label='口座番号'
          placeholder='1234567'
          value={accountNumber}
          onChange={setAccountNumber}
          errMessage={errMessage.accountNumber}
          isUseHelperText={true}
          isUseNecessity={true}
          isRequired={true}
        />
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <FormLabel sx={{ color: '#1a1a1a' }}>
            <Stack direction='row'>
              <p>口座名義</p>
              <p className='required'>必須</p>
            </Stack>
            <div style={{ fontSize: '12px', textAlign: 'left' }}>
              <p>※口座名義が個人名でない権利者の方はセイの欄にご記入ください。</p>
              <p>※ご入力いただいた口座名義は自動的に半角文字に変換されます。使用可能な文字は以下の通りです。</p>
              <p>カタカナ、英数字、スペース、　<ruby> - <rt>ﾊｲﾌﾝ</rt> </ruby>　 <ruby> () <rt>丸括弧</rt> </ruby> 　<ruby> 「」 <rt>鉤括弧</rt> </ruby> 　<ruby> . <rt>ﾋﾟﾘｵﾄﾞ</rt> </ruby>　<ruby> / <rt>ｽﾗｯｼｭ</rt> </ruby></p>
            </div>
          </FormLabel>
        </FormControl>
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
          <Stack direction='row'>
            <span style={{ maxWidth: 190, marginRight: 10 }}>
              <InputAccountNameBox
                id='accountSei'
                name='accountSei'
                label='ｾｲ'
                placeholder='ｼﾞｭｷﾞｮｳ'
                value={accountSei}
                setValue={setAccountSei}
                errMessage={errMessage.accountSei}
                isUseHelperText={true}
              />
            </span>
            <span style={{ maxWidth: 190, marginLeft: 10 }}>
              <InputAccountNameBox
                id='accountMei'
                name='accountMei'
                label='ﾒｲ'
                placeholder='ﾀﾛｳ'
                value={accountMei}
                setValue={setAccountMei}
                errMessage={errMessage.accountMei}
                isUseHelperText={true}
              />
            </span>
          </Stack>
        </FormControl>
      </Grid>

      <Grid item>
        <CustomButton
          label='次へ'
          variant='contained'
          onClick={handleTransitionNext}
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/self/individual/addressinfo')}
        />
      </Grid>
    </Grid >
  )
}

export default IndividualAccountInfoForm