import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { convertToHENumbers } from './converter.js';

// インボイス番号入力ボックス
function InputInvoiceNumberBox(props) {
  return (
    <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
      <TextField
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        onBlur={e => props.onChange(convertToHENumbers(e.target.value))}
        onKeyUp={e => props.onKeyUp(e)}
        variant='outlined'
        size='small'
        type='Text'
        inputProps={{
          inputMode: 'numeric',
        }}
      />
    </FormControl>
  )
}

export default InputInvoiceNumberBox