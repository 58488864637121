import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton'
import CorporateAccountInfo from '../components/CorporateAccountInfo';
import { validateInputs } from '../components/validateInputs.js';
import { smoothScroll } from '../components/smoothScroll.js';

function AuthorizationAccountInfoForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [bankName, setBankName] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '' : inputData['AuthorizationAccountInfoForm'].bankName);
  const [bankCode, setBankCode] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '' : inputData['AuthorizationAccountInfoForm'].bankCode);
  const [branchName, setBranchName] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '' : inputData['AuthorizationAccountInfoForm'].branchName);
  const [branchId, setBranchId] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '' : inputData['AuthorizationAccountInfoForm'].branchId);
  const [accountNumber, setAccountNumber] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '' : inputData['AuthorizationAccountInfoForm'].accountNumber);
  const [accountType, setAccountType] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '1' : inputData['AuthorizationAccountInfoForm'].accountType.toString());
  const [accountName, setAccountName] = useState(inputData['AuthorizationAccountInfoForm'] === undefined ? '' : inputData['AuthorizationAccountInfoForm'].accountName);
  const [isAgree1, setIsAgree1] = useState(false);
  const [isAgree2, setIsAgree2] = useState(false);
  const [isAgree3, setIsAgree3] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    const json = { bankName, bankCode, branchName, branchId, accountType, accountNumber, accountName };
    const [isErr, message] = validateInputs(json);
    setErrMessage([]);
    if (isErr) {
      setErrMessage(message);
      smoothScroll(Object.keys(message)[0]);
    }
    else {
      setInputData({ ...inputData, 'AuthorizationAccountInfoForm': json });
      props.handleTransition('/others/authorization/additionalcode');
    }
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        補償金振込先の銀行口座情報をご入力ください。
      </Grid>

      <Grid item style={{ width: '100%', textAlign: 'center' }}>
        <CorporateAccountInfo
          bankName={bankName}
          bankCode={bankCode}
          branchName={branchName}
          branchId={branchId}
          accountType={accountType}
          accountNumber={accountNumber}
          accountName={accountName}
          isAgree1={isAgree1}
          isAgree2={isAgree2}
          isAgree3={isAgree3}
          setBankName={setBankName}
          setBankCode={setBankCode}
          setBranchName={setBranchName}
          setBranchId={setBranchId}
          setAccountType={setAccountType}
          setAccountNumber={setAccountNumber}
          setAccountName={setAccountName}
          setIsAgree1={setIsAgree1}
          setIsAgree2={setIsAgree2}
          setIsAgree3={setIsAgree3}

          errMessage={errMessage}
          setErrMessage={setErrMessage}
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='次へ'
          variant='contained'
          disabled={!isAgree1 || !isAgree2 || !isAgree3}
          onClick={handleTransitionNext}
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/others/authorization/contactinfo')}
        />
      </Grid>
    </Grid>
  )
}

export default AuthorizationAccountInfoForm