import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ContentsDetailTable from './ContentsDetailTable';
import CustomButton from './CustomButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: 'solid 1px',
  borderRadius: '4px',
  borderColor: '#1976D2',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

// 利用報告明細テーブル
function ContentsDetailModal(props) {
  return (
    <Box sx={{ ...style }}>
      <span style={{ marginTop: 50 }}>※権利者の皆様にお伝えできる情報の全てを掲載しております。</span>
      <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
        <Grid item xs={12} sx={{ marginTop: 5, width: '100%', maxHeight: 400, overflowY: 'auto' }}>
          {props.target.map((target, index) => (
            <Box sx={{
              border: 'solid 1px',
              borderRadius: '4px',
              borderColor: '#1976D2',
              overflow: 'hidden',
              margin: 1,
              padding: 1,
            }}>
              <ContentsDetailTable
                id={target.id}
                data={target.data}
              />
            </Box>
          ))}
        </Grid>

        <Grid item xs={12}>
          <CustomButton
            label='閉じる'
            variant='text'
            width={100}
            onClick={props.onClose}
          />
        </Grid>
      </Grid>
    </Box >
  )
}

export default ContentsDetailModal