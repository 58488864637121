import React from 'react';
import { IMaskInput } from 'react-imask';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// 認証コード入力ボックスのスタイル
const style = {
  border: 'solid 2px',
  borderRadius: '8px',
  borderColor: '#1a1a1a',
  textAlign: 'center',
  width: '100%',
  maxWidth: 500,
  marginLeft: 0,
};

// 認証コード入力マスク
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='aaa - aaa - aaa - aaa'
      definitions={{
        'a': /[0-9a-zA-Z]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { id: props.id, value } })}
      overwrite
    />
  );
});

// 複数認証コード入力画面
function AdditionalCodeComponent(props) {
  // ペア枠追加
  const addAuthComponent = () => {
    const value = [...props.inputValueList, { 'copyrightHolderId': '', 'code': '' }];
    props.setInputValueList(value);
  };

  // ペア枠削除
  const deleteAuthComponent = (index) => {
    const tempArray = props.inputValueList.slice();
    tempArray.splice(index, 1);
    props.setInputValueList(tempArray);
  };

  // id入力イベント
  const handleChangeId = (index, value) => {
    const authInfo = props.inputValueList[index];
    authInfo.copyrightHolderId = value;
    const tempArray = props.inputValueList.slice();
    tempArray[index] = authInfo;
    props.setInputValueList(tempArray);
  };

  // code入力イベント
  const handleChangeCode = (index, value) => {
    const authInfo = props.inputValueList[index];
    authInfo.code = value.replace(/ - /g, '');
    const tempArray = props.inputValueList.slice();
    tempArray[index] = authInfo;
    props.setInputValueList(tempArray);
  };

  return (
    <React.Fragment>
      <Grid item alignItems='flex-start' style={{ width: '100%' }}>
        <p>複数の権利者ID等がお手元に届いた方は、このフォームからまとめて登録することができます。</p>
        <p>追加入力がない場合は、そのまま次の画面にお進みください。</p>
      </Grid>

      <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ ...style }}>
          <Grid container alignItems='center' justifyContent='center' direction='column'>
            <Grid item style={{ width: '100%', textAlign: 'center', marginTop: 10, marginBottom: 20 }}>
              1番目（ログイン時に入力済み）
            </Grid>

            <Grid item style={{ width: '90%', textAlign: 'center', marginBottom: 20 }}>
              <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
                <FormLabel sx={{ color: '#1a1a1a' }}>
                  <Stack direction='row'>
                    権利者ID
                  </Stack>
                </FormLabel>
                <TextField
                  id='firstId'
                  value={props.inputData['AuthForm'].copyrightHolderId}
                  variant='outlined'
                  size='small'
                  InputProps={{ readOnly: true }}
                  inputProps={{ 'data-testid': 'multiple-firstid' }}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ width: '90%', textAlign: 'center', marginBottom: 20 }}>
              <FormControl variant='standard' style={{ width: '100%', maxWidth: 400 }}>
                <FormLabel sx={{ color: '#1a1a1a' }}>
                  <Stack direction='row'>
                    認証コード
                  </Stack>
                </FormLabel>
                <TextField
                  id='firstCode'
                  value={props.inputData['AuthForm'].code}
                  variant='outlined'
                  size='small'
                  InputProps={{ readOnly: true, inputComponent: TextMaskCustom }}
                  inputProps={{ 'data-testid': 'multiple-firstcode' }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {
        props.inputValueList.map((item, index) => (
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ ...style }}>
              <Stack direction='row' justifyContent='flex-end' style={{ marginTop: 0, marginRight: 0 }}>
                <IconButton
                  onClick={() => deleteAuthComponent(index)}
                  size='small'
                  data-testid={'del-' + (index + 1).toString()}
                >
                  <CloseIcon sx={{ color: '#D32F2F' }} />
                </IconButton>
              </Stack>

              <Grid container alignItems='center' justifyContent='center' direction='column' style={{ marginTop: -20 }}>
                <FormControl name={index} error={props.errMessage[index] !== undefined} variant='standard' style={{ width: '100%', maxWidth: 400 }}>
                  <Grid item style={{ width: '100%', textAlign: 'center', marginBottom: 20 }}>
                    {index + 2}番目
                  </Grid>

                  <Grid item style={{ width: '100%', maxWidth: 400, textAlign: 'center', marginBottom: 20 }}>
                    <Stack direction='column'>
                      <FormLabel sx={{ color: '#1a1a1a', textAlign: 'left' }}>
                        権利者ID
                      </FormLabel>
                      <TextField
                        error={props.errMessage[index] !== undefined}
                        defaultValue={item.copyrightHolderId}
                        value={props.inputValueList[index].copyrightHolderId}
                        onChange={(e) => handleChangeId(index, e.target.value)}
                        variant='outlined'
                        size='small'
                        inputProps={{ 'data-testid': 'multiple-id-' + (index).toString() }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item style={{ width: '100%', maxWidth: 400, textAlign: 'center', marginBottom: 20 }}>
                    <Stack direction='column'>
                      <FormLabel sx={{ color: '#1a1a1a', textAlign: 'left' }}>
                        認証コード
                      </FormLabel>
                      <TextField
                        error={props.errMessage[index] !== undefined}
                        defaultValue={item.code}
                        value={props.inputValueList[index].code}
                        onChange={(e) => handleChangeCode(index, e.target.value)}
                        variant='outlined'
                        size='small'
                        InputProps={{ inputComponent: TextMaskCustom }}
                        inputProps={{ 'data-testid': 'multiple-code-' + (index).toString() }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item className='errorMessage' style={{ width: '100%', maxWidth: 400, marginTop: -5, marginBottom: 10 }}>
                    {props.errMessage[index] !== undefined ? props.errMessage[index] : ''}
                  </Grid>
                </FormControl>
              </Grid>
            </Box>
          </Grid >

        ))
      }

      <Grid item style={{ width: '100%', maxWidth: 500 }}>
        <Stack direction='row' justifyContent='flex-start'>
          <Button
            variant='outlined'
            onClick={addAuthComponent}
          >
            権利者ID等を追加
          </Button>
        </Stack>
      </Grid>
    </React.Fragment>
  )
}

export default AdditionalCodeComponent