import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Context } from '../../Content';
import CustomButton from '../components/CustomButton'
import IndividualBasicinfo from '../components/IndividualBasicinfo';
import { validateInputs } from '../components/validateInputs.js';
import { smoothScroll } from '../components/smoothScroll.js';

// 注意書きのスタイル
const style = {
	height: 'auto',
	border: 'solid 1px',
	borderRadius: '2px',
	borderColor: 'red',
	padding: 2
};

function IndividualBasicInfoForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;

	const [firstName, setFirstName] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].firstName);
	const [lastName, setLastName] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].lastName);
	const [firstNameFurigana, setFirstNameFurigana] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].firstNameFurigana);
	const [lastNameFurigana, setLastNameFurigana] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].lastNameFurigana);
	const [email, setEmail] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].email);
	const [phoneNumber, setPhoneNumber] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].phoneNumber);
	const [invoiceNumber, setInvoiceNumber] = useState(inputData['IndividualBasicInfoForm'] === undefined ? '' : inputData['IndividualBasicInfoForm'].invoiceNumber);
	const [errMessage, setErrMessage] = useState('');

	// ステップ移動時の処理
	const handleTransitionNext = () => {
		const json = { lastName, firstName, lastNameFurigana, firstNameFurigana, email, phoneNumber, invoiceNumber };
		const [isErr, message] = validateInputs(json);
		setErrMessage([]);
		if (isErr) {
			setErrMessage(message);
			smoothScroll(Object.keys(message)[0]);
		}
		else {
			setInputData({ ...inputData, 'IndividualBasicInfoForm': json });
			props.handleTransition('self/individual/addressinfo');
		}
	};

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item alignItems='flex-start' style={{ width: '100%' }}>
				<p>権利者個人の名前や連絡先等をご入力ください。</p>
			</Grid>

			<Grid item style={{ width: '100%' }}>
				<Box sx={{ ...style }}>
					<p>この画面の「姓」「名」を支払い明細書に記載いたしますので、必ず本名をご入力ください。</p>
				</Box>
			</Grid>

			<Grid item>
				<IndividualBasicinfo
					firstName={firstName}
					lastName={lastName}
					firstNameFurigana={firstNameFurigana}
					lastNameFurigana={lastNameFurigana}
					email={email}
					phoneNumber={phoneNumber}
					invoiceNumber={invoiceNumber}
					setFirstName={setFirstName}
					setLastName={setLastName}
					setFirstNameFurigana={setFirstNameFurigana}
					setLastNameFurigana={setLastNameFurigana}
					setEmail={setEmail}
					setPhoneNumber={setPhoneNumber}
					setInvoiceNumber={setInvoiceNumber}
					errMessage={errMessage}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='次へ'
					variant='contained'
					onClick={() => handleTransitionNext('/self/individual/addressinfo')}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/type')}
				/>
			</Grid>
		</Grid>
	)
}

export default IndividualBasicInfoForm