import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Context } from '../Content';
import CustomButton from './components/CustomButton';
import DetailComponent from './components/DetailComponent';

function DetailsForm(props) {
	const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
	const { inputData, setInputData } = inputDataContext;

	return (
		<Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
			<Grid item alignItems='flex-start' style={{ width: '100%', marginBottom: -10 }}>
				<p>本制度のもとで利用された著作物情報及び補償金額(※1)をご確認ください。</p>
				<p>なお、この明細は後の画面（最終確認）で再度ご覧いただくことができ、PDFファイルとしてダウンロードすることができます。</p>
				<p style={{ fontSize: '14px' }}>※1 表示の金額から、分配業務受託団体等が規程に定める手数料や諸税を控除した額が、ご指定の口座へ振り込まれます。</p>
			</Grid>

			<Grid item style={{ width: '100%', maxWidth: 600, marginTop: 20 }}>
				<DetailComponent
					data={inputData['Details']}
					isAdditional={false}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='次へ'
					variant='contained'
					onClick={() => props.handleTransition('/type')}
				/>
			</Grid>

			<Grid item>
				<CustomButton
					label='戻る'
					variant='text'
					onClick={() => props.handleTransition('/terms')}
				/>
			</Grid>
		</Grid >
	)
}

export default DetailsForm