import React, { useState } from 'react';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import InputTextBox from './InputTextBox';
import axios from 'axios';

// モーダルのスタイル
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  border: 'solid 1px',
  borderRadius: '4px',
  borderColor: '#1976D2',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
};

// 検索結果ボックスのスタイル
const resultBoxStyle = {
  height: 300,
  border: 'solid 1px',
  borderRadius: '8px',
  borderColor: '#5c5c5c',
  overflowY: 'scroll',
  padding: 1
};

// 銀行選択ダイアログ
function SearchBankModal(props) {
  const [searchWord, setSearchWord] = useState('');
  const [searchResultList, setSearchResultList] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchBankName = () => {
    setSearchResultList([]);
    setIsLoading(true);
    setIsSearched(false);
    axios
      .get(process.env.REACT_APP_API_DOMAIN + '/v1/bank/?name=' + searchWord)
      .then(res => {
        const list = [];
        res.data.forEach(element => {
          list.push({ 'name': element.bank_name, 'code': element.bank_code });
        });
        setIsLoading(false);
        setSearchResultList(list);
        setIsSearched(true);
      })
      .catch(err => {
        setIsLoading(false);
        setIsSearched(true);
      });
  };

  const handleKeyPress = (e) => {
    if (!e.nativeEvent.isComposing && e.key === 'Enter') handleSearchBankName();
  }

  return (
    <Box sx={{ ...modalStyle }}>
      <Grid container spacing={3} alignItems='center' justifyContent='center'>
        <Grid item xs={12}>
          <InputTextBox
            id='bankName'
            label='銀行名を検索後、選択してください。'
            placeholder='みずほ'
            value={searchWord}
            onChange={setSearchWord}
            onKeyUp={handleKeyPress}
            isUseNecessity={false}
          />
        </Grid>

        <Grid item xs={6}>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='contained'
            onClick={() => handleSearchBankName()}
            disabled={isLoading}
            fullWidth
          >
            検索
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='text'
            onClick={() => props.onClose()}
            fullWidth
          >
            閉じる
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ ...resultBoxStyle }}>
            <div style={{ display: searchResultList.length === 0 && isSearched ? 'block' : 'none' }} className='errorMessage'>
              <p>見つかりませんでした。</p>
              <br />
              <p>●銀行名の一部で検索が可能です</p>
              <p>＜例＞「三菱ＵＦＪ」→「三菱」</p>
              <p>　　　「三井住友」→「住友」</p>
              <p>●アルファベット は全角でご入力ください</p>
              <p>＜例＞「PayPay」→「ＰａｙＰａｙ」</p>
              <p>●末尾の「銀行」は省略してご入力ください</p>
            </div>

            <div>
              {searchResultList.map((item, index) => (
                <Button
                  variant='outlined'
                  onClick={() => props.onDecideBank(item)}
                  sx={{
                    p: 1,
                    m: 1,
                    maxHeight: 42.5,
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '-400px', pointerEvents: 'none' }}>
          <Fade in={isLoading}>
            <CircularProgress />
          </Fade>
        </Grid>
      </Grid>
    </Box >
  );
};

export default SearchBankModal