import Button from '@mui/material/Button';

// オリジナルのボタンコンポーネント
function CustomButton(props) {
  const width = props.width === undefined ? 200 : props.width;
  const dataTestId = props.dataTestId;
  return (
    <Button
      variant={props.variant}
      color={props.color}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ textAlign: 'center', width: width }}
      data-testid={dataTestId}
    >
      {props.label}
    </Button>
  );
};

export default CustomButton
