import React from 'react';
import { Page, Text, View, Font, Document, StyleSheet } from '@react-pdf/renderer';
import fontRegular from '../../fonts/NotoSansJP-Regular.ttf';
import fontBold from '../../fonts/NotoSansJP-Bold.ttf';

// 明細を表示するコンポーネント
function ContentsDetailDocument(props) {

  if (props.items[0].data === undefined) return(<Document></Document>);

  const BORDER_COLOR = '#0d0d0d';
  const BORDER_STYLE = 'solid';
  const COLN_WIDTH = 100 / 14;
  const TITLE_FONT_SIZE = 8;
  const CELL_FONT_SIZE = 4;
  const NotoSansJpRegular = 'NotoSansJP-Regular';
  const NotoSansJpBold = 'NotoSansJP-Bold';

  // フォント「NotoSansJP レギュラー」
  Font.register({
    family: NotoSansJpRegular,
    src: fontRegular
  });

  // フォント「NotoSansJP 太字」
  Font.register({
    family: NotoSansJpBold,
    src: fontBold
  });

  Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, '']));

  const styles = StyleSheet.create({
    body: {
      padding: 10
    },
    title: {
      margin: 5,
      fontSize: TITLE_FONT_SIZE,
      fontFamily: NotoSansJpBold
    },
    table: {
      display: 'table',
      width: '95%',
      alignContent: 'center',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      margin: 10
    },
    tableRow: {
      flexDirection: 'row'
    },
    tableHeader: {
      width: COLN_WIDTH + '%',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCol: {
      width: COLN_WIDTH + '%',
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCellHeader: {
      margin: 5,
      fontSize: CELL_FONT_SIZE,
      fontFamily: NotoSansJpBold
    },
    tableCell: {
      margin: 5,
      fontSize: CELL_FONT_SIZE,
      fontFamily: NotoSansJpRegular,
    }
  })

  return (
    <Document>
      {props.items?.map((item, index) => (
        <Page size='A4' orientation='landscape' style={styles.body} wrap>
          <Text style={styles.title}>権利者ID {item.id} の利用報告明細</Text>
          <Text style={styles.title}>※分配業務受託団体等が規程に定める手数料や諸税が控除される前の金額を表示しております。</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {Object.keys(item.data[0])?.map((key, index) => (
                <View style={styles.tableHeader}>
                  <Text style={styles.tableCellHeader}>{key}</Text>
                </View>
              ))}
            </View>

            {item?.data.map((data, index) => (
              <View style={styles.tableRow} wrap={false}>
                {Object.keys(item.data[0])?.map((key, index) => (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data[key].value}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ContentsDetailDocument