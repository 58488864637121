import React, { useState, useContext } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import CustomButton from '../components/CustomButton'
import AdditionalCodeComponent from '../components/AdditionalCodeComponent';
import { Context } from '../../Content';
import { duplicateValidateInputs } from '../components/validateInputs.js';
import { smoothScroll } from '../components/smoothScroll.js';

// 複数認証コード入力画面
function AgentAdditionalCodeForm(props) {
  const [inputDataContext, isCorrectTransitionContext] = useContext(Context);
  const { inputData, setInputData } = inputDataContext;

  const [errMessage, setErrMessage] = useState({});
  const [inputValueList, setInputValueList] = useState(inputData['AgentAdditionalCodeForm'] === undefined ? [] : inputData['AgentAdditionalCodeForm']);

  // ステップ移動時の処理
  const handleTransitionNext = () => {
    const json = [];
    const inputIndexArr = [];

    json.push({
      copyright_holder_id: inputData['AuthForm'].copyrightHolderId,
      code: inputData['AuthForm'].code
    });
    inputValueList.forEach((item, i) => {
      inputIndexArr.push(item.copyrightHolderId);
      json.push({
        copyright_holder_id: item.copyrightHolderId,
        code: item.code
      });
    });

    const [isErr, message] = duplicateValidateInputs(json);

    if (isErr) {
      setErrMessage(message);
      smoothScroll(Object.keys(message)[0]);
    }
    else {
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN + '/v1/authenticate/',
          json
        )
        .then(res => {
          setInputData({
            ...inputData,
            'AgentAdditionalCodeForm': inputValueList,
            'Details': res.data
          });
          props.handleTransition('/others/agent/totaldetails');
        })
        .catch(err => {
          const keys = Object.keys(err.response?.data);
          const errMessage = {};
          keys.forEach(key => {
            if (typeof err.response?.data[key] === "string") {
              errMessage[inputIndexArr.indexOf(key)] = err.response?.data[key];
            }
          });
          setErrMessage(errMessage);
          smoothScroll(Object.keys(errMessage)[0]);
        });
    }
  };

  return (
    <Grid container spacing={3} alignItems='center' justifyContent='center' direction='column'>
      <AdditionalCodeComponent
        inputData={inputData}
        inputValueList={inputValueList}
        setInputValueList={setInputValueList}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        handleTransitionNext={handleTransitionNext}
      />

      <Grid item>
        <CustomButton
          label='次へ'
          variant='contained'
          onClick={handleTransitionNext}
          dataTestId='multiple-nextbutton'
        />
      </Grid>

      <Grid item>
        <CustomButton
          label='戻る'
          variant='text'
          onClick={() => props.handleTransition('/others/agent/individualaddressinfo')}
        />
      </Grid>
    </Grid>
  )
}

export default AgentAdditionalCodeForm